import { pick } from "@schtappe/utils"

import * as Shared from "~/models/shared"
import * as Auth from "~/models/auth"
import * as IvrTree from "~/models/ivr_tree"
import * as Profile from "~/models/profile"
import * as Landing from "~/models/landing"
import * as Admin from "~/models/admin"

import AccountRequestedPlan from "~/models/account_requested_plan"
import Account from "~/models/account"
import CallerId from "~/models/caller_id"
import SmsMasking from "~/models/sms_masking"
import Billing from "~/models/billing"
import Campaign from "~/models/campaign"
import User from "~/models/user"
import CustomEmail from "~/models/custom_email"
import AdminUser from "~/models/admin_user"
import Inquiry from "~/models/inquiry"
import AccountPlan from "~/models/account_plan"
import CampaignSetting from "~/models/campaign_setting"
import Wallet from "~/models/wallet"
import OutboundCallTransaction from "~/models/outbound_call_transaction"

const createUrl = (urlOrFn, options) =>
        typeof urlOrFn === "function"
                ? urlOrFn(options)
                : urlOrFn

const defineApi = (config = {}) => (options = {}) => {
        const { settings, method } = config
        let { url } = config
        let { queryParser, bodyParser, errorParser, successParser } = settings || {}

        let query
        if (queryParser) {
                // TODO(aes): implement toQueryObject() to flatten object to query string
                const result = queryParser(options)
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                query = result.data
        }

        let body
        if (bodyParser) {
                const result = bodyParser(options)
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                body = result.data
        }

        errorParser ||= (_) => ({ success: false })
        successParser ||= (data) => ({ success: true, data })

        const resolvedUrl = createUrl(url, options)

        const fetchOptions = {
                method,
                query,
                body,
                onResponse({ response }) {
                        if (!response.ok) {
                                if (options.onError) {
                                        const result = errorParser(response._data)
                                        if (result.success) {
                                                options.onError({
                                                        type: "ERROR_RESPONSE",
                                                        payload: result.data.errors,
                                                })
                                                return
                                        } else {
                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                }
                                return
                        }

                        if (options.onSuccess) {
                                const result = successParser(response._data)
                                if (!result.success) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_PARSE",
                                                        payload: result,
                                                })
                                        } else {
                                                console.error(result)
                                                throw new Error(`API for ${resolvedUrl} has unhandled error`)
                                        }
                                        return
                                }

                                options.onSuccess(result.data)
                                return
                        }
                },
        }

        return useAuthorizedFetch(resolvedUrl, fetchOptions)
}

const AuthApi = {
        preLogin(options = { email: "", password: "", g_recaptcha_response: "", onError: () => { }, onSuccess: () => { } }) { 
                const { email = '', password = '', g_recaptcha_response = '' } = pick(
                    ["email", "password", "g_recaptcha_response"],
                    options
                );

                const result = Auth.Request.PreLogin({ email, password, g_recaptcha_response });
                      

                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/api/v2/auth/prelogin", {
                        method: "POST",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                const result = Auth.Error.PreLogin(response._data)
                                                if (!result.success) {
                                                        options.onError({
                                                                type: "ERROR_STATUS",
                                                                payload: response,
                                                        })
                                                        return
                                                }

                                                options.onError({
                                                        type: "ERROR_RESPONSE",
                                                        payload: result.data,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        const result = Auth.Response.PreLogin(response._data)
                                        if (!result.success) {
                                                if (options.onError) {
                                                        options.onError({
                                                                type: "ERROR_PARSE",
                                                                payload: result,
                                                        })
                                                }
                                                return
                                        }

                                        options.onSuccess(result.data)
                                }
                        },
                })
        },
        preLoginAdmin(options = { email: "", password: "", g_recaptcha_response: "", is_admin: false, onError: () => { }, onSuccess: () => { } }) { 
                const { email = '', password = '', g_recaptcha_response = '', is_admin = false } = pick(
                    ["email", "password", "g_recaptcha_response", "is_admin"],
                    options
                );

            
                const result = Auth.Request.PreLoginAdmin({ email, password, g_recaptcha_response, is_admin });
                      

                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/api/v2/auth/prelogin", {
                        method: "POST",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                const result = Auth.Error.PreLoginAdmin(response._data)
                                                if (!result.success) {
                                                        options.onError({
                                                                type: "ERROR_STATUS",
                                                                payload: response,
                                                        })
                                                        return
                                                }

                                                options.onError({
                                                        type: "ERROR_RESPONSE",
                                                        payload: result.data,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        const result = Auth.Response.PreLoginAdmin(response._data)
                                        if (!result.success) {
                                                if (options.onError) {
                                                        options.onError({
                                                                type: "ERROR_PARSE",
                                                                payload: result,
                                                        })
                                                }
                                                return
                                        }

                                        options.onSuccess(result.data)
                                }
                        },
                })
        },
        sign_in(options = { otp_code: "", g_recaptcha_response: "", email: "",  onError: () => { }, onSuccess: () => { } }) {
                const { otp_code = '', g_recaptcha_response = '', email = '' } = pick(
                        ["otp_code", "g_recaptcha_response", "email"],
                        options
                );
                const result = Auth.Request.SignIn({ user: { otp_code, g_recaptcha_response, email } });
                      

                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/users/sign_in", {
                        method: "POST",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                const result = Auth.Error.SignIn(response._data)
                                                if (!result.success) {
                                                        options.onError({
                                                                type: "ERROR_STATUS",
                                                                payload: response,
                                                        })
                                                        return
                                                }

                                                options.onError({
                                                        type: "ERROR_RESPONSE",
                                                        payload: result.data,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        const result = Auth.Response.SignIn(response._data)
                                        if (!result.success) {
                                                if (options.onError) {
                                                        options.onError({
                                                                type: "ERROR_PARSE",
                                                                payload: result,
                                                        })
                                                }
                                                return
                                        }
                                        options.onSuccess(result.data)
                                }
                        },
                })
        },
        admin_sign_in(options = { otp_code: "", email: "", onError: () => { }, onSuccess: () => { } }) {
                const { otp_code = '', email = '' } = pick(
                        ["otp_code", "email"],
                        options
                );
                const result = Auth.Request.AdminSignIn({ admin_user: { 'otp': otp_code, email, is_admin: true } });
                      

                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/admin/login", {
                        method: "POST",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                const result = Auth.Error.AdminSignIn(response._data)
                                                if (!result.success) {
                                                        options.onError({
                                                                type: "ERROR_STATUS",
                                                                payload: response,
                                                        })
                                                        return
                                                }

                                                options.onError({
                                                        type: "ERROR_RESPONSE",
                                                        payload: result.data,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        const result = Auth.Response.AdminSignIn(response._data)
                                        if (!result.success) {
                                                if (options.onError) {
                                                        options.onError({
                                                                type: "ERROR_PARSE",
                                                                payload: result,
                                                        })
                                                }
                                                return
                                        }
                                        options.onSuccess(result.data)
                                }
                        },
                })
        },
        resendOtp(options = { email: "", onError: () => { }, onSuccess: () => { } }) {
                const { email } = pick(["email"], options);
                const result = Auth.Request.ResendOtp({
                        "email": email || '',
                        // "otp_secret_key": otp_secret_key || ''
                })

                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/api/v2/auth/resend_otp", {
                        method: "POST",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                const result = Auth.Error.ValidateOtp(response._data)
                                                if (!result.success) {
                                                        options.onError({
                                                                type: "ERROR_STATUS",
                                                                payload: response,
                                                        })
                                                        return
                                                }

                                                options.onError({
                                                        type: "ERROR_RESPONSE",
                                                        payload: result.data,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        const result = Auth.Response.ValidateOtp(response._data)
                                        if (!result.success) {
                                                if (options.onError) {
                                                        options.onError({
                                                                type: "ERROR_PARSE",
                                                                payload: result,
                                                        })
                                                }
                                                return
                                        }

                                        options.onSuccess(result.data)
                                }
                        },
                })
        },
        resendOtpAdmin(options = { email: "", onError: () => { }, onSuccess: () => { } }) {
                const { email } = pick(["email"], options);
                const result = Auth.Request.ResendOtpAdmin({ email, is_admin: true })

                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/api/v2/auth/resend_otp", {
                        method: "POST",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                const result = Auth.Error.ValidateOtpAdmin(response._data)
                                                if (!result.success) {
                                                        options.onError({
                                                                type: "ERROR_STATUS",
                                                                payload: response,
                                                        })
                                                        return
                                                }

                                                options.onError({
                                                        type: "ERROR_RESPONSE",
                                                        payload: result.data,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        const result = Auth.Response.ValidateOtpAdmin(response._data)
                                        if (!result.success) {
                                                if (options.onError) {
                                                        options.onError({
                                                                type: "ERROR_PARSE",
                                                                payload: result,
                                                        })
                                                }
                                                return
                                        }

                                        options.onSuccess(result.data)
                                }
                        },
                })
        },
        forgot_password(options = { email: "", onError: () => { }, onSuccess: () => { } }) {
                const result = Auth.Request.ForgotPassword({
                        user: { email: options.email },
                })
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/users/password", {
                        method: "POST",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                const result = Shared.Response.Errors(response._data)
                                                if (result.success) {
                                                        options.onError({
                                                                type: "ERROR_RESPONSE",
                                                                payload: result.data.errors,
                                                        })
                                                } else {
                                                        options.onError({
                                                                type: "ERROR_STATUS",
                                                                payload: response,
                                                        })
                                                }
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        options.onSuccess(response._data)
                                }
                        },
                })
        },
        set_password(options = { form: {}, onError: () => { }, onSuccess: () => { } }) {
                const result = Auth.Request.SetPassword({
                        user: options.form,
                })
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/users/password", {
                        method: "PUT",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                const result = Shared.Response.Errors(response._data)
                                                if (result.success) {
                                                        options.onError({
                                                                type: "ERROR_RESPONSE",
                                                                payload: result.data.errors,
                                                        })
                                                } else {
                                                        options.onError({
                                                                type: "ERROR_STATUS",
                                                                payload: response,
                                                        })
                                                }
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        options.onSuccess(response._data)
                                }
                        },
                })
        },
        forgotPasswordAdmin(options = { email: "", onError: () => { }, onSuccess: () => { } }) {
                const result = Auth.Request.ForgotPasswordAdmin({
                        admin_user: { email: options.email },
                })
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/admin/password", {
                        method: "POST",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                const result = Shared.Response.Errors(response._data)
                                                if (result.success) {
                                                        options.onError({
                                                                type: "ERROR_RESPONSE",
                                                                payload: result.data.errors,
                                                        })
                                                } else {
                                                        options.onError({
                                                                type: "ERROR_STATUS",
                                                                payload: response,
                                                        })
                                                }
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        options.onSuccess(response._data)
                                }
                        },
                })
        },
        setPasswordAdmin(options = { form: {}, onError: () => { }, onSuccess: () => { } }) {
                const result = Auth.Request.SetPasswordAdmin({
                        admin_user: options.form,
                })
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/admin/password", {
                        method: "PUT",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                const result = Shared.Response.Errors(response._data)
                                                if (result.success) {
                                                        options.onError({
                                                                type: "ERROR_RESPONSE",
                                                                payload: result.data.errors,
                                                        })
                                                } else {
                                                        options.onError({
                                                                type: "ERROR_STATUS",
                                                                payload: response,
                                                        })
                                                }
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        options.onSuccess(response._data)
                                }
                        },
                })
        },
}

const LandingApi = {
        inquiries(options = { onError: () => { }, onSuccess: () => { } }) {
                const result = Landing.Request.Inquiries({
                        "recaptcha": options.recaptcha,
                        inquiry: options.form,
                })
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/inquiries", {
                        method: "POST",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        options.onSuccess(response._data)
                                }
                        },
                })
        },
        experience(options = { mobile_number: "", onError: () => { }, onSuccess: () => { } }) {
                const result = Landing.Request.Experience({
                        mobile_number: options.mobile_number,
                })
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/experience_ives", {
                        method: "POST",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                const result = Landing.Error.Experience(response._data)
                                                if (!result.success) {
                                                        options.onError({
                                                                type: "ERROR_PARSE",
                                                                payload: result,
                                                        })
                                                        return
                                                }

                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: result.data.error,
                                                })
                                        }
                                        return
                                }

                                if (options.onError) {
                                        const result = Landing.Error.Experience(response._data)
                                        if (result.success) {
                                                options.onError({
                                                        type: "ERROR_RESPONSE",
                                                        payload: result.data.error,
                                                })
                                                return
                                        }
                                }

                                if (options.onSuccess) {
                                        const result = Landing.Response.Experience(response._data)
                                        if (!result.success) {
                                                if (options.onError) {
                                                        options.onError({
                                                                type: "ERROR_PARSE",
                                                                payload: result,
                                                        })
                                                }
                                                return
                                        }

                                        options.onSuccess(result.data)
                                }
                        },
                })
        },
}

const IvrTreeApi = {
        form_options(options = { onError: () => { }, onSuccess: () => { } }) {
                return useAuthorizedFetch("/ivr_trees/form_options", {
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                const result = IvrTree.Response.FormOptions(response._data)
                                if (!result.success) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_PARSE",
                                                        payload: result,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        options.onSuccess(result.data)
                                }
                        },
                })
        }
}

const ProfileApi = {
        show(options = { onError: () => { }, onSuccess: () => { } }) {
                return useAuthorizedFetch(`/profiles/${options.id}.json`, {
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                const result = Profile.Response.Details(response._data)
                                if (!result.success) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_PARSE",
                                                        payload: result,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        options.onSuccess(result.data.data)
                                }
                        },
                })
        },

        update(options = { onError: () => { }, onSuccess: () => { } }) {
                const result = Profile.Request.Update({ user: options.form })
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch(`/profiles/${options.id}.js`, {
                        method: "PATCH",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        const result = {
                                                isEmailChanged: !!response._data.match(new RegExp("Please check and verify your email address.")),
                                                isMobileChanged: !!response._data.match(new RegExp("modal-enter-verification")),
                                                hasErrors: !!response._data.match(new RegExp("error-container")),
                                                data: response._data,
                                        }
                                        options.onSuccess(result)
                                }
                        },
                })
        },

        form_options(options = { onError: () => { }, onSuccess: () => { } }) {
                return useAuthorizedFetch("/profiles/form_options", {
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                const result = Profile.Response.FormOptions(response._data)
                                if (!result.success) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_PARSE",
                                                        payload: result,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        options.onSuccess(result.data)
                                }
                        },
                })
        },

        validate_mobile_changes(options = { onError: () => { }, onSuccess: () => { } }) {
                return useAuthorizedFetch("/validate_mobile_changes.js", {
                        method: "POST",
                        body: {
                                pin_one: options.pin[0],
                                pin_two: options.pin[1],
                                pin_three: options.pin[2],
                                pin_four: options.pin[3],
                        },
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        const result = {
                                                isPinInvalid: !!response._data.match(new RegExp("PIN invalid")),
                                        }
                                        options.onSuccess(result)
                                }
                        },
                })
        },

        change_password(options = { onError: () => { }, onSuccess: () => { } }) {
                const result = Profile.Request.ChangePassword({ user: options.form })
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch(`/profiles/${options.id}/change_password.js`, {
                        method: "PATCH",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        const result = {
                                                hasErrors: !!response._data.match(new RegExp("change-password-error-container")),
                                                data: response._data,
                                        }
                                        options.onSuccess(result)
                                }
                        },
                })
        },

        verify_email(options = {}) {
                const { user_id, confirmation_token, onError, onSuccess } = options || {}
                const result = Profile.Request.VerifyEmail({ confirmation_token })
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch(`/profiles/${user_id}/verify_email`, {
                        query: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (onError) {
                                                onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                const result = Profile.Response.VerifyEmail(response._data)
                                if (!result.success) {
                                        if (onError) {
                                                onError({
                                                        type: "ERROR_PARSE",
                                                        payload: result,
                                                })
                                        }
                                        return
                                }

                                if (onSuccess) {
                                        onSuccess(result.data)
                                }
                        },
                })
        },

        verify_mobtel(options = {}) {
                const { pin, user_id, onError, onSuccess } = options
                const result = Profile.Request.VerifyMobtel({
                        user: {
                                pin_one: pin.one,
                                pin_two: pin.two,
                                pin_three: pin.three,
                                pin_four: pin.four,
                        }
                })
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch(`/profiles/${user_id}/verify_mobtel.js`, {
                        method: "POST",
                        headers: {
                                accept: "text/javascript",
                        },
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (onError) {
                                                onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                if (onSuccess) {
                                        onSuccess(response._data)
                                }
                        },
                })
        },

        resend_email_verification(options = {}) {
                const { user_id, onError, onSuccess } = options || {}
                return useAuthorizedFetch(`/profiles/${user_id}/resend_email_verification`, {
                        headers: {
                                accept: "text/javascript",
                        },
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (onError) {
                                                onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                if (onSuccess) {
                                        onSuccess(response._data)
                                }
                        },
                })
        },

        resend_otp(options = {}) {
                const { user_id, onError, onSuccess } = options || {}
                return useAuthorizedFetch(`/profiles/${user_id}/resend_otp`, {
                        headers: {
                                accept: "text/javascript",
                        },
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (onError) {
                                                onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                if (onSuccess) {
                                        onSuccess(response._data)
                                }
                        },
                })
        },
}

const BillingApi = {
        show(options = { id: 0, onError: () => { }, onSuccess: () => { } }) {
                return useAuthorizedFetch(`/billings/${options.id}`, {
                        headers: {
                                accept: "text/javascript",
                        },
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        options.onSuccess(response._data)
                                }
                        },
                })
        }
}

// TODO(aes): deduplicate these
const AdminApi = {
        Dashboard: {
                show: defineApi({
                        url: "/api/v1/admin/dashboard",
                        settings: Admin.Api.Dashboard,
                }),
        },

        [Account.key]: {
                filter_options: defineApi({
                        url: "/admin/accounts/filter_options",
                        settings: Account.Api.FilterOptions,
                }),
                list: defineApi({
                        url: "/admin/accounts.json",
                        settings: Account.Api.List,
                }),
                form_options: defineApi({
                        url: "/admin/accounts/form_options",
                        settings: Account.Api.FormOptions,
                }),
                wizard: defineApi({
                        method: "POST",
                        url: "/admin/accounts/wizard",
                        settings: Account.Api.Wizard,
                }),
                wizard_finish: defineApi({
                        method: "POST",
                        url: "/admin/accounts/wizard_finish",
                        settings: Account.Api.WizardFinish,
                }),
                show: defineApi({
                        url: ({ account_id }) => `/admin/accounts/${account_id}`,
                        settings: Account.Api.Show,
                }),
                update: defineApi({
                        method: "PATCH",
                        url: ({ form }) => `/admin/accounts/${form.id}`,
                        settings: Account.Api.Update,
                })
        },

        [AccountRequestedPlan.key]: {
                form_options: defineApi({
                        url: "/admin/account_requested_plans/form_options",
                        settings: AccountRequestedPlan.Api.FormOptions,
                }),
                create: defineApi({
                        method: "POST",
                        url: ({ account_id }) => account_id
                                ? `/admin/accounts/${account_id}/account_requested_plans`
                                : `/admin/account_requested_plans`,
                        settings: AccountRequestedPlan.Api.Create,
                }),
                filter_options: defineApi({
                        url: "/admin/account_requested_plans/filter_options",
                        settings: AccountRequestedPlan.Api.FilterOptions,
                }),
                list: defineApi({
                        url: ({ account_id }) => account_id
                                ? `/admin/accounts/${account_id}/account_requested_plans.json`
                                : "/admin/account_requested_plans.json",
                        settings: AccountRequestedPlan.Api.List,
                }),
                count: defineApi({
                        url: ({ account_id }) => account_id
                                ? `/admin/accounts/${account_id}/account_requested_plans/count`
                                : `/admin/account_requested_plans/count`,
                        settings: AccountRequestedPlan.Api.Count,
                }),
                show: defineApi({
                        url: ({ account_id, account_requested_plan_id }) => account_id
                                ? `/admin/accounts/${account_id}/account_requested_plans/${account_requested_plan_id}`
                                : `/admin/account_requested_plans/${account_requested_plan_id}`,
                        settings: AccountRequestedPlan.Api.Show,
                }),
                update: defineApi({
                        method: "PATCH",
                        url: ({ account_id, account_requested_plan_id }) => account_id
                                ? `/admin/accounts/${account_id}/account_requested_plans/${account_requested_plan_id}`
                                : `/admin/account_requested_plans/${account_requested_plan_id}`,
                        settings: AccountRequestedPlan.Api.Update,
                }),
                approve: defineApi({
                        method: "POST",
                        url: ({ account_requested_plan_id }) => `/admin/account_requested_plans/${account_requested_plan_id}/approve`,
                        settings: AccountRequestedPlan.Api.Approve,
                }),
                disapprove: defineApi({
                        method: "POST",
                        url: ({ account_requested_plan_id }) => `/admin/account_requested_plans/${account_requested_plan_id}/disapprove`,
                        settings: AccountRequestedPlan.Api.Disapprove,
                }),
        },

        [CallerId.key]: {
                form_options: defineApi({
                        url: "/admin/caller_ids/form_options",
                        settings: CallerId.Api.FormOptions,
                }),
                create: defineApi({
                        method: "POST",
                        url: ({ account_id }) => account_id
                                ? `/admin/accounts/${account_id}/caller_ids`
                                : `/admin/caller_ids`,
                        settings: CallerId.Api.Create,
                }),
                filter_options: defineApi({
                        url: "/admin/caller_ids/filter_options",
                        settings: CallerId.Api.FilterOptions,
                }),
                list: defineApi({
                        url: ({ account_id }) => account_id
                                ? `/admin/accounts/${account_id}/caller_ids.json`
                                : "/admin/caller_ids.json",
                        settings: CallerId.Api.List,
                }),
                count: defineApi({
                        url: ({ account_id }) => account_id
                                ? `/admin/accounts/${account_id}/caller_ids/count`
                                : `/admin/caller_ids/count`,
                        settings: CallerId.Api.Count,
                }),
                show: defineApi({
                        url: ({ account_id, caller_id_id }) => account_id
                                ? `/admin/accounts/${account_id}/caller_ids/${caller_id_id}`
                                : `/admin/caller_ids/${caller_id_id}`,
                        settings: CallerId.Api.Show,
                }),
                update: defineApi({
                        method: "PATCH",
                        url: ({ account_id, caller_id_id }) => account_id
                                ? `/admin/accounts/${account_id}/caller_ids/${caller_id_id}`
                                : `/admin/caller_ids/${caller_id_id}`,
                        settings: CallerId.Api.Update,
                }),
                approve: defineApi({
                        method: "POST",
                        url: ({ caller_id_id }) => `/admin/caller_ids/${caller_id_id}/approve`,
                        settings: CallerId.Api.Approve,
                }),
                disapprove: defineApi({
                        method: "POST",
                        url: ({ caller_id_id }) => `/admin/caller_ids/${caller_id_id}/disapprove`,
                        settings: CallerId.Api.Disapprove,
                }),
        },

        [SmsMasking.key]: {
                form_options: defineApi({
                        url: "/admin/sms_maskings/form_options",
                        settings: SmsMasking.Api.FormOptions,
                }),
                create: defineApi({
                        method: "POST",
                        url: ({ account_id }) => account_id
                                ? `/admin/accounts/${account_id}/sms_maskings`
                                : `/admin/sms_maskings`,
                        settings: SmsMasking.Api.Create,
                }),
                filter_options: defineApi({
                        url: "/admin/sms_maskings/filter_options",
                        settings: SmsMasking.Api.FilterOptions,
                }),
                list: defineApi({
                        url: ({ account_id }) => account_id
                                ? `/admin/accounts/${account_id}/sms_maskings.json`
                                : "/admin/sms_maskings.json",
                        settings: SmsMasking.Api.List,
                }),
                count: defineApi({
                        url: ({ account_id }) => account_id
                                ? `/admin/accounts/${account_id}/sms_maskings/count`
                                : `/admin/sms_maskings/count`,
                        settings: SmsMasking.Api.Count,
                }),
                show: defineApi({
                        url: ({ sms_masking_id }) => `/admin/sms_maskings/${sms_masking_id}`,
                        settings: SmsMasking.Api.Show,
                }),
                update: defineApi({
                        method: "PATCH",
                        url: ({ account_id, sms_masking_id }) => account_id
                                ? `/admin/accounts/${account_id}/sms_maskings/${sms_masking_id}`
                                : `/admin/sms_maskings/${sms_masking_id}`,
                        settings: SmsMasking.Api.Update,
                }),
                approve: defineApi({
                        method: "POST",
                        url: ({ sms_masking_id }) => `/admin/sms_maskings/${sms_masking_id}/approve`,
                        settings: SmsMasking.Api.Approve,
                }),
                disapprove: defineApi({
                        method: "POST",
                        url: ({ sms_masking_id }) => `/admin/sms_maskings/${sms_masking_id}/disapprove`,
                        settings: SmsMasking.Api.Disapprove,
                }),
                test_sms: defineApi({
                        method: "POST",
                        url: ({ sms_masking_id }) => `/admin/sms_maskings/${sms_masking_id}/test_sms`,
                        settings: SmsMasking.Api.TestSms,
                })
        },

        [Billing.key]: {
                form_options: defineApi({
                        url: "/admin/billings/form_options",
                        settings: Billing.Api.FormOptions,
                }),
                filter_options: defineApi({
                        url: "/admin/billings/filter_options",
                        settings: Billing.Api.FilterOptions,
                }),
                list: defineApi({
                        url: "/admin/billings.json",
                        settings: Billing.Api.List,
                }),
                show: defineApi({
                        url: ({ billing_id }) => `/admin/billings/${billing_id}`,
                        settings: Billing.Api.Show,
                }),
                update: defineApi({
                        method: "PATCH",
                        url: ({ billing_id }) => `/admin/billings/${billing_id}`,
                        settings: Billing.Api.Update,
                }),
        },

        [Campaign.key]: {
                filter_options: defineApi({
                        url: "/admin/campaigns/filter_options",
                        settings: Campaign.Api.FilterOptions,
                }),
                list: defineApi({
                        url: "/admin/campaigns.json",
                        settings: Campaign.Api.List,
                }),
                count: defineApi({
                        url: "/admin/campaigns/count",
                        settings: Campaign.Api.Count,
                }),
                show: defineApi({
                        url: ({ campaign_id }) => `/admin/campaigns/${campaign_id}`,
                        settings: Campaign.Api.Show,
                }),
        },
        [User.key]: {
                form_options: defineApi({
                        url: "/admin/users/form_options",
                        settings: User.Api.FormOptions,
                }),
                access_rights_list: defineApi({
                        url: "/admin/users/access_rights_list",
                        settings: User.Api.AccessRightsList,
                }),
                create: defineApi({
                        method: "POST",
                        url: "/admin/users",
                        settings: User.Api.Create,
                }),
                filter_options: defineApi({
                        url: "/admin/users/filter_options",
                        settings: User.Api.FilterOptions,
                }),
                list: defineApi({
                        url: "/admin/users.json",
                        settings: User.Api.List,
                }),
                show: defineApi({
                        url: ({ user_id }) => `/admin/users/${user_id}`,
                        settings: User.Api.Show,
                }),
                update: defineApi({
                        method: "PATCH",
                        url: ({ user_id }) => `/admin/users/${user_id}`,
                        settings: User.Api.Update,
                }),
        },
        [CustomEmail.key]: {
                create: defineApi({
                        method: "POST",
                        url: "/admin/custom_emails",
                        settings: CustomEmail.Api.Create,
                }),
                list: defineApi({
                        url: "/admin/custom_emails.json",
                        settings: CustomEmail.Api.List,
                }),
                show: defineApi({
                        url: ({ custom_email_id }) => `/admin/custom_emails/${custom_email_id}`,
                        settings: CustomEmail.Api.Show,
                }),
                delete: defineApi({
                        method: "DELETE",
                        url: ({ custom_email_id }) => `/admin/custom_emails/${custom_email_id}`,
                        settings: CustomEmail.Api.Delete,
                }),
        },
        [AdminUser.key]: {
                create: defineApi({
                        method: "POST",
                        url: "/admin/admin_users",
                        settings: AdminUser.Api.Create,
                }),
                list: defineApi({
                        url: "/admin/admin_users.json",
                        settings: AdminUser.Api.List,
                }),
                show: defineApi({
                        url: ({ admin_user_id }) => `/admin/admin_users/${admin_user_id}`,
                        settings: AdminUser.Api.Show,
                }),
                update: defineApi({
                        method: "PATCH",
                        url: ({ admin_user_id }) => `/admin/admin_users/${admin_user_id}`,
                        settings: AdminUser.Api.Update,
                }),
        },
        [Inquiry.key]: {
                list: defineApi({
                        url: "/admin/inquiries.json",
                        settings: Inquiry.Api.List,
                }),
                show: defineApi({
                        url: ({ inquiry_id }) => `/admin/inquiries/${inquiry_id}`,
                        settings: Inquiry.Api.Show,
                }),
        },
        [AccountPlan.key]: {
                form_options: defineApi({
                        url: "/admin/account_plans/form_options",
                        settings: AccountPlan.Api.FormOptions,
                }),
                show: defineApi({
                        url: ({ account_plan_id }) => `/admin/account_plans/${account_plan_id}`,
                        settings: AccountPlan.Api.Show,
                }),
                update: defineApi({
                        method: "PATCH",
                        url: ({ account_plan_id }) => `/admin/account_plans/${account_plan_id}`,
                        settings: AccountPlan.Api.Update,
                }),
        },
        [CampaignSetting.key]: {
                show: defineApi({
                        url: ({ campaign_setting_id }) => `/admin/campaign_settings/${campaign_setting_id}`,
                        settings: CampaignSetting.Api.Show,
                }),
                update: defineApi({
                        method: "PATCH",
                        url: ({ campaign_setting_id }) => `/admin/campaign_settings/${campaign_setting_id}`,
                        settings: CampaignSetting.Api.Update,
                }),
        },
        [Wallet.key]: {
                show: defineApi({
                        url: ({ wallet_id }) => `/admin/wallets/${wallet_id}`,
                        settings: Wallet.Api.Show,
                }),
                update: defineApi({
                        method: "PATCH",
                        url: ({ wallet_id }) => `/admin/wallets/${wallet_id}`,
                        settings: Wallet.Api.Update,
                }),
        },
        [OutboundCallTransaction.key]: {
                form_options: defineApi({
                        url: "/admin/campaigns/0/outbound_call_transactions/form_options",
                        settings: OutboundCallTransaction.Api.FormOptions,
                }),
                list: defineApi({
                        url: ({ campaign_id }) => `/admin/campaigns/${campaign_id}/outbound_call_transactions.json`,
                        settings: OutboundCallTransaction.Api.List,
                }),
                show: defineApi({
                        url: ({ campaign_id, outbound_call_transaction_id }) => `/admin/campaigns/${campaign_id}/outbound_call_transactions/${outbound_call_transaction_id}`,
                        settings: OutboundCallTransaction.Api.Show,
                }),
                update: defineApi({
                        method: "PATCH",
                        url: ({ campaign_id, outbound_call_transaction_id }) => `/admin/campaigns/${campaign_id}/outbound_call_transactions/${outbound_call_transaction_id}`,
                        settings: OutboundCallTransaction.Api.Update,
                }),
        },

        reports_form_options(options = { onError: () => { }, onSuccess: () => { } }) {
                return useAuthorizedFetch("/admin/reports/form_options", {
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                const result = Admin.Response.ReportsFormOptions(response._data)
                                if (!result.success) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_PARSE",
                                                        payload: result,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        options.onSuccess(result.data)
                                }
                        },
                })
        },

        reports_generate(options = { form: {}, onError: () => { }, onSuccess: () => { } }) {
                const form = options.form
                const result = Admin.Request.ReportsGenerate({
                        admin_reports_form: {
                                category: String(form.category),
                                report: String(form.report),
                        },
                        options: {
                                client_group: String(form.client_group),
                                year: String(form.year),
                        },
                })
                if (!result.success) {
                        // TODO(aes)
                        console.error(result)
                        return
                }
                return useAuthorizedFetch("/admin/reports/generate", {
                        method: "POST",
                        body: result.data,
                        onResponse({ response }) {
                                if (!response.ok) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_STATUS",
                                                        payload: response,
                                                })
                                        }
                                        return
                                }

                                const result = Admin.Response.ReportsGenerate(response._data)
                                if (!result.success) {
                                        if (options.onError) {
                                                options.onError({
                                                        type: "ERROR_PARSE",
                                                        payload: result,
                                                })
                                        }
                                        return
                                }

                                if (options.onSuccess) {
                                        options.onSuccess(result.data)
                                }
                        },
                })
        },
}

export default defineNuxtPlugin((_nuxtApp) => {
        const api = {
                Auth: AuthApi,
                Landing: LandingApi,
                IvrTree: IvrTreeApi,
                Profile: ProfileApi,
                Billing: BillingApi,
                Admin: AdminApi,
        }
        return { provide: { api } }
})
