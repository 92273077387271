import { default as editTFwIbxkgimMeta } from "/codebuild/output/src538496046/src/pages/admin/account_plans/[account_plan_id]/edit.vue?macro=true";
import { default as indexJK6G9fWyDvMeta } from "/codebuild/output/src538496046/src/pages/admin/account_plans/[account_plan_id]/index.vue?macro=true";
import { default as _91account_plan_id_93fZkhb63y8FMeta } from "/codebuild/output/src538496046/src/pages/admin/account_plans/[account_plan_id].vue?macro=true";
import { default as editdNHvit9rOtMeta } from "/codebuild/output/src538496046/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as index4jOxSwa1toMeta } from "/codebuild/output/src538496046/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_939d39Wg4nIRMeta } from "/codebuild/output/src538496046/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexjhO2n40yEGMeta } from "/codebuild/output/src538496046/src/pages/admin/account_requested_plans/index.vue?macro=true";
import { default as newwBIbdilZuQMeta } from "/codebuild/output/src538496046/src/pages/admin/account_requested_plans/new.vue?macro=true";
import { default as account_requested_planseY8OrETgsmMeta } from "/codebuild/output/src538496046/src/pages/admin/account_requested_plans.vue?macro=true";
import { default as editEORRBtaedeMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as index5uf6BtAQ0jMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_9374FkUWvHj6Meta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as index5ppI76mCqpMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue?macro=true";
import { default as newKeK4vRCkDBMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plansPYik9Bz5kpMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans.vue?macro=true";
import { default as edit4aZZoWn4T6Meta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexil0BLmRoXSMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_931Nx7yDOQB9Meta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue?macro=true";
import { default as index0w0sNfun0aMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids/index.vue?macro=true";
import { default as new1IiJuvWWwKMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids/new.vue?macro=true";
import { default as caller_idsmD1v9BpXAlMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids.vue?macro=true";
import { default as editGzaeozFyPoMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/edit.vue?macro=true";
import { default as indexaeNsCeedwBMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/index.vue?macro=true";
import { default as edit0ffMTN0vEoMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexRUpiiEdN3CMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93CT4G3iOpOZMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexcS1sSS9VrXMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue?macro=true";
import { default as newZeXuUqJmtoMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue?macro=true";
import { default as sms_maskings60ylaoC2w3Meta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings.vue?macro=true";
import { default as _91account_id_93zL97nlTSgPMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/[account_id].vue?macro=true";
import { default as indexz2BQhfH574Meta } from "/codebuild/output/src538496046/src/pages/admin/accounts/index.vue?macro=true";
import { default as wizard_plan_selectionCJdRfk5NS6Meta } from "/codebuild/output/src538496046/src/pages/admin/accounts/wizard_plan_selection.vue?macro=true";
import { default as wizarduWDuV77AwfMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts/wizard.vue?macro=true";
import { default as accounts43vAzWsPjoMeta } from "/codebuild/output/src538496046/src/pages/admin/accounts.vue?macro=true";
import { default as editZBTs0CA1lpMeta } from "/codebuild/output/src538496046/src/pages/admin/admin_users/[admin_user_id]/edit.vue?macro=true";
import { default as indexgPXirJ3FUFMeta } from "/codebuild/output/src538496046/src/pages/admin/admin_users/[admin_user_id]/index.vue?macro=true";
import { default as _91admin_user_id_93MAG1NLG3mJMeta } from "/codebuild/output/src538496046/src/pages/admin/admin_users/[admin_user_id].vue?macro=true";
import { default as indexDvAVAojSHlMeta } from "/codebuild/output/src538496046/src/pages/admin/admin_users/index.vue?macro=true";
import { default as newC9PVA46fntMeta } from "/codebuild/output/src538496046/src/pages/admin/admin_users/new.vue?macro=true";
import { default as admin_usersqht8vQF9hgMeta } from "/codebuild/output/src538496046/src/pages/admin/admin_users.vue?macro=true";
import { default as indexFRz96N49BhMeta } from "/codebuild/output/src538496046/src/pages/admin/audit_trails/index.vue?macro=true";
import { default as audit_trailsAREzMvSVoWMeta } from "/codebuild/output/src538496046/src/pages/admin/audit_trails.vue?macro=true";
import { default as editK0w6PiXzWMMeta } from "/codebuild/output/src538496046/src/pages/admin/billings/[billing_id]/edit.vue?macro=true";
import { default as index7g82aCKq4DMeta } from "/codebuild/output/src538496046/src/pages/admin/billings/[billing_id]/index.vue?macro=true";
import { default as _91billing_id_93cD1pDiWQzeMeta } from "/codebuild/output/src538496046/src/pages/admin/billings/[billing_id].vue?macro=true";
import { default as indexZ2WXFbR6WQMeta } from "/codebuild/output/src538496046/src/pages/admin/billings/index.vue?macro=true";
import { default as newM17IezlsDfMeta } from "/codebuild/output/src538496046/src/pages/admin/billings/new.vue?macro=true";
import { default as billingska0hKMyJLYMeta } from "/codebuild/output/src538496046/src/pages/admin/billings.vue?macro=true";
import { default as edit2wdY8BKNuBMeta } from "/codebuild/output/src538496046/src/pages/admin/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexNScgU5hiMhMeta } from "/codebuild/output/src538496046/src/pages/admin/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93Tlo82hWtwJMeta } from "/codebuild/output/src538496046/src/pages/admin/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexehfW2JzR83Meta } from "/codebuild/output/src538496046/src/pages/admin/caller_ids/index.vue?macro=true";
import { default as newZurUbi79kLMeta } from "/codebuild/output/src538496046/src/pages/admin/caller_ids/new.vue?macro=true";
import { default as caller_idsiF9lQbEqyoMeta } from "/codebuild/output/src538496046/src/pages/admin/caller_ids.vue?macro=true";
import { default as editj07an19l4rMeta } from "/codebuild/output/src538496046/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue?macro=true";
import { default as indextdbB1c3JALMeta } from "/codebuild/output/src538496046/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue?macro=true";
import { default as _91campaign_setting_id_931eAwStTlkVMeta } from "/codebuild/output/src538496046/src/pages/admin/campaign_settings/[campaign_setting_id].vue?macro=true";
import { default as edit2cMOHexdz3Meta } from "/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/edit.vue?macro=true";
import { default as index1pED0Gkfa6Meta } from "/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/index.vue?macro=true";
import { default as edit9xiaXqo2m7Meta } from "/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue?macro=true";
import { default as indexi28upBRk15Meta } from "/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue?macro=true";
import { default as _91outbound_call_transaction_id_93nwgc3kJjvpMeta } from "/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue?macro=true";
import { default as index0Ft1oe2AeeMeta } from "/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue?macro=true";
import { default as newUA6CBYr28hMeta } from "/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue?macro=true";
import { default as outbound_call_transactions2TP0b8dzk9Meta } from "/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue?macro=true";
import { default as _91campaign_id_93y1e6RDEUVrMeta } from "/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id].vue?macro=true";
import { default as indexfTLaO2i2KAMeta } from "/codebuild/output/src538496046/src/pages/admin/campaigns/index.vue?macro=true";
import { default as newCnChjHchDOMeta } from "/codebuild/output/src538496046/src/pages/admin/campaigns/new.vue?macro=true";
import { default as campaigns4NwP9xUop0Meta } from "/codebuild/output/src538496046/src/pages/admin/campaigns.vue?macro=true";
import { default as indexavs9oMYocYMeta } from "/codebuild/output/src538496046/src/pages/admin/custom_emails/[custom_email_id]/index.vue?macro=true";
import { default as _91custom_email_id_93TXzmteGZ4jMeta } from "/codebuild/output/src538496046/src/pages/admin/custom_emails/[custom_email_id].vue?macro=true";
import { default as indexyvfYgxKlHYMeta } from "/codebuild/output/src538496046/src/pages/admin/custom_emails/index.vue?macro=true";
import { default as new3bY7R1D5HGMeta } from "/codebuild/output/src538496046/src/pages/admin/custom_emails/new.vue?macro=true";
import { default as custom_emailsAGYXQkBb8FMeta } from "/codebuild/output/src538496046/src/pages/admin/custom_emails.vue?macro=true";
import { default as indexErfkS6Xyf3Meta } from "/codebuild/output/src538496046/src/pages/admin/dashboard/index.vue?macro=true";
import { default as dashboard4DsrY0lSsPMeta } from "/codebuild/output/src538496046/src/pages/admin/dashboard.vue?macro=true";
import { default as indexMDiqtlKhIEMeta } from "/codebuild/output/src538496046/src/pages/admin/inquiries/[inquiry_id]/index.vue?macro=true";
import { default as _91inquiry_id_93GREGRVoXLTMeta } from "/codebuild/output/src538496046/src/pages/admin/inquiries/[inquiry_id].vue?macro=true";
import { default as indexi9HZdp7Pe9Meta } from "/codebuild/output/src538496046/src/pages/admin/inquiries/index.vue?macro=true";
import { default as new0Sj5txG8pgMeta } from "/codebuild/output/src538496046/src/pages/admin/inquiries/new.vue?macro=true";
import { default as inquiriesODlUzL3xmRMeta } from "/codebuild/output/src538496046/src/pages/admin/inquiries.vue?macro=true";
import { default as indexKB1gwfxadQMeta } from "/codebuild/output/src538496046/src/pages/admin/login/index.vue?macro=true";
import { default as loginFVSAVHFuvzMeta } from "/codebuild/output/src538496046/src/pages/admin/login.vue?macro=true";
import { default as indexinTpGHWtBUMeta } from "/codebuild/output/src538496046/src/pages/admin/password/edit/index.vue?macro=true";
import { default as index718PKDUOXRMeta } from "/codebuild/output/src538496046/src/pages/admin/password/new/index.vue?macro=true";
import { default as indextWcq4V2BwwMeta } from "/codebuild/output/src538496046/src/pages/admin/reports/index.vue?macro=true";
import { default as reportsXRe5Walx7NMeta } from "/codebuild/output/src538496046/src/pages/admin/reports.vue?macro=true";
import { default as editlrafUvgbLjMeta } from "/codebuild/output/src538496046/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexiT0YppvMvAMeta } from "/codebuild/output/src538496046/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93z9XTqBL614Meta } from "/codebuild/output/src538496046/src/pages/admin/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as index5htBCm7AiUMeta } from "/codebuild/output/src538496046/src/pages/admin/sms_maskings/index.vue?macro=true";
import { default as newExJaEVOHFhMeta } from "/codebuild/output/src538496046/src/pages/admin/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsPly2vgOXYqMeta } from "/codebuild/output/src538496046/src/pages/admin/sms_maskings.vue?macro=true";
import { default as editBuq5KvwJ3LMeta } from "/codebuild/output/src538496046/src/pages/admin/users/[user_id]/edit.vue?macro=true";
import { default as indexPdiUbJvjHRMeta } from "/codebuild/output/src538496046/src/pages/admin/users/[user_id]/index.vue?macro=true";
import { default as _91user_id_93ZWcnhvUr1MMeta } from "/codebuild/output/src538496046/src/pages/admin/users/[user_id].vue?macro=true";
import { default as indexEOfeHYUwqvMeta } from "/codebuild/output/src538496046/src/pages/admin/users/index.vue?macro=true";
import { default as newHO4yxMqFOTMeta } from "/codebuild/output/src538496046/src/pages/admin/users/new.vue?macro=true";
import { default as usersMEnkxFbViQMeta } from "/codebuild/output/src538496046/src/pages/admin/users.vue?macro=true";
import { default as editayM5BkhzxrMeta } from "/codebuild/output/src538496046/src/pages/admin/wallets/[wallet_id]/edit.vue?macro=true";
import { default as indexWIAl2euehoMeta } from "/codebuild/output/src538496046/src/pages/admin/wallets/[wallet_id]/index.vue?macro=true";
import { default as _91wallet_id_9323WxlGHjSgMeta } from "/codebuild/output/src538496046/src/pages/admin/wallets/[wallet_id].vue?macro=true";
import { default as adminIiZNN2RmL5Meta } from "/codebuild/output/src538496046/src/pages/admin.vue?macro=true";
import { default as indexCmtRyZgr58Meta } from "/codebuild/output/src538496046/src/pages/audit_logs/index.vue?macro=true";
import { default as indexqeKWwUHoruMeta } from "/codebuild/output/src538496046/src/pages/billings/index.vue?macro=true";
import { default as indexzSxOk88BW4Meta } from "/codebuild/output/src538496046/src/pages/call_out/reports/index.vue?macro=true";
import { default as _91id_93wo5mslhZKJMeta } from "/codebuild/output/src538496046/src/pages/call_recordings/[id].vue?macro=true";
import { default as indexLOdCBfJ2lCMeta } from "/codebuild/output/src538496046/src/pages/campaigns/[id]/index.vue?macro=true";
import { default as indexv8l5Wki73GMeta } from "/codebuild/output/src538496046/src/pages/campaigns/index.vue?macro=true";
import { default as indexwnbMqLTrIkMeta } from "/codebuild/output/src538496046/src/pages/dashboard/index.vue?macro=true";
import { default as index6V6nWvmrxOMeta } from "/codebuild/output/src538496046/src/pages/help/index.vue?macro=true";
import { default as indexSutvlBJwRXMeta } from "/codebuild/output/src538496046/src/pages/index.vue?macro=true";
import { default as indexv58wIZBvwVMeta } from "/codebuild/output/src538496046/src/pages/inquiry/index.vue?macro=true";
import { default as editor5z45V7c4cHMeta } from "/codebuild/output/src538496046/src/pages/ivr_trees/[id]/editor.vue?macro=true";
import { default as indexwRPYZxolMSMeta } from "/codebuild/output/src538496046/src/pages/ivr_trees/index.vue?macro=true";
import { default as verify_emailqXs5heqhs9Meta } from "/codebuild/output/src538496046/src/pages/profiles/[user_id]/verify_email.vue?macro=true";
import { default as welcome_dashboardcQeN7LjUYpMeta } from "/codebuild/output/src538496046/src/pages/profiles/[user_id]/welcome_dashboard.vue?macro=true";
import { default as indexrKNGUodBNAMeta } from "/codebuild/output/src538496046/src/pages/profiles/index.vue?macro=true";
import { default as indexSqXg3DTXs7Meta } from "/codebuild/output/src538496046/src/pages/sub_users/users/index.vue?macro=true";
import { default as index08ZD9vakJiMeta } from "/codebuild/output/src538496046/src/pages/terms_and_conditions/index.vue?macro=true";
import { default as indexUzUovQtZf4Meta } from "/codebuild/output/src538496046/src/pages/users/password/edit/index.vue?macro=true";
import { default as indexJAV1573BcIMeta } from "/codebuild/output/src538496046/src/pages/users/password/new/index.vue?macro=true";
import { default as index4HqjW9FzuJMeta } from "/codebuild/output/src538496046/src/pages/users/sign_in/index.vue?macro=true";
export default [
  {
    name: adminIiZNN2RmL5Meta?.name ?? "admin",
    path: adminIiZNN2RmL5Meta?.path ?? "/admin",
    children: [
  {
    path: _91account_plan_id_93fZkhb63y8FMeta?.path ?? "account_plans/:account_plan_id()",
    children: [
  {
    name: editTFwIbxkgimMeta?.name ?? "admin-account_plans-account_plan_id-edit",
    path: editTFwIbxkgimMeta?.path ?? "edit",
    meta: editTFwIbxkgimMeta || {},
    alias: editTFwIbxkgimMeta?.alias || [],
    redirect: editTFwIbxkgimMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/account_plans/[account_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexJK6G9fWyDvMeta?.name ?? "admin-account_plans-account_plan_id",
    path: indexJK6G9fWyDvMeta?.path ?? "",
    meta: indexJK6G9fWyDvMeta || {},
    alias: indexJK6G9fWyDvMeta?.alias || [],
    redirect: indexJK6G9fWyDvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/account_plans/[account_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_plan_id_93fZkhb63y8FMeta?.name ?? undefined,
    meta: _91account_plan_id_93fZkhb63y8FMeta || {},
    alias: _91account_plan_id_93fZkhb63y8FMeta?.alias || [],
    redirect: _91account_plan_id_93fZkhb63y8FMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/account_plans/[account_plan_id].vue").then(m => m.default || m)
  },
  {
    path: account_requested_planseY8OrETgsmMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_939d39Wg4nIRMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editdNHvit9rOtMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id-edit",
    path: editdNHvit9rOtMeta?.path ?? "edit",
    meta: editdNHvit9rOtMeta || {},
    alias: editdNHvit9rOtMeta?.alias || [],
    redirect: editdNHvit9rOtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index4jOxSwa1toMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id",
    path: index4jOxSwa1toMeta?.path ?? "",
    meta: index4jOxSwa1toMeta || {},
    alias: index4jOxSwa1toMeta?.alias || [],
    redirect: index4jOxSwa1toMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_939d39Wg4nIRMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_939d39Wg4nIRMeta || {},
    alias: _91account_requested_plan_id_939d39Wg4nIRMeta?.alias || [],
    redirect: _91account_requested_plan_id_939d39Wg4nIRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexjhO2n40yEGMeta?.name ?? "admin-account_requested_plans",
    path: indexjhO2n40yEGMeta?.path ?? "",
    meta: indexjhO2n40yEGMeta || {},
    alias: indexjhO2n40yEGMeta?.alias || [],
    redirect: indexjhO2n40yEGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newwBIbdilZuQMeta?.name ?? "admin-account_requested_plans-new",
    path: newwBIbdilZuQMeta?.path ?? "new",
    meta: newwBIbdilZuQMeta || {},
    alias: newwBIbdilZuQMeta?.alias || [],
    redirect: newwBIbdilZuQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_planseY8OrETgsmMeta?.name ?? undefined,
    meta: account_requested_planseY8OrETgsmMeta || {},
    alias: account_requested_planseY8OrETgsmMeta?.alias || [],
    redirect: account_requested_planseY8OrETgsmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: accounts43vAzWsPjoMeta?.path ?? "accounts",
    children: [
  {
    path: _91account_id_93zL97nlTSgPMeta?.path ?? ":account_id()",
    children: [
  {
    path: account_requested_plansPYik9Bz5kpMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_9374FkUWvHj6Meta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editEORRBtaedeMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id-edit",
    path: editEORRBtaedeMeta?.path ?? "edit",
    meta: editEORRBtaedeMeta || {},
    alias: editEORRBtaedeMeta?.alias || [],
    redirect: editEORRBtaedeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index5uf6BtAQ0jMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id",
    path: index5uf6BtAQ0jMeta?.path ?? "",
    meta: index5uf6BtAQ0jMeta || {},
    alias: index5uf6BtAQ0jMeta?.alias || [],
    redirect: index5uf6BtAQ0jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_9374FkUWvHj6Meta?.name ?? undefined,
    meta: _91account_requested_plan_id_9374FkUWvHj6Meta || {},
    alias: _91account_requested_plan_id_9374FkUWvHj6Meta?.alias || [],
    redirect: _91account_requested_plan_id_9374FkUWvHj6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: index5ppI76mCqpMeta?.name ?? "admin-accounts-account_id-account_requested_plans",
    path: index5ppI76mCqpMeta?.path ?? "",
    meta: index5ppI76mCqpMeta || {},
    alias: index5ppI76mCqpMeta?.alias || [],
    redirect: index5ppI76mCqpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newKeK4vRCkDBMeta?.name ?? "admin-accounts-account_id-account_requested_plans-new",
    path: newKeK4vRCkDBMeta?.path ?? "new",
    meta: newKeK4vRCkDBMeta || {},
    alias: newKeK4vRCkDBMeta?.alias || [],
    redirect: newKeK4vRCkDBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plansPYik9Bz5kpMeta?.name ?? undefined,
    meta: account_requested_plansPYik9Bz5kpMeta || {},
    alias: account_requested_plansPYik9Bz5kpMeta?.alias || [],
    redirect: account_requested_plansPYik9Bz5kpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: caller_idsmD1v9BpXAlMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_931Nx7yDOQB9Meta?.path ?? ":caller_id_id()",
    children: [
  {
    name: edit4aZZoWn4T6Meta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id-edit",
    path: edit4aZZoWn4T6Meta?.path ?? "edit",
    meta: edit4aZZoWn4T6Meta || {},
    alias: edit4aZZoWn4T6Meta?.alias || [],
    redirect: edit4aZZoWn4T6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexil0BLmRoXSMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id",
    path: indexil0BLmRoXSMeta?.path ?? "",
    meta: indexil0BLmRoXSMeta || {},
    alias: indexil0BLmRoXSMeta?.alias || [],
    redirect: indexil0BLmRoXSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_931Nx7yDOQB9Meta?.name ?? undefined,
    meta: _91caller_id_id_931Nx7yDOQB9Meta || {},
    alias: _91caller_id_id_931Nx7yDOQB9Meta?.alias || [],
    redirect: _91caller_id_id_931Nx7yDOQB9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: index0w0sNfun0aMeta?.name ?? "admin-accounts-account_id-caller_ids",
    path: index0w0sNfun0aMeta?.path ?? "",
    meta: index0w0sNfun0aMeta || {},
    alias: index0w0sNfun0aMeta?.alias || [],
    redirect: index0w0sNfun0aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: new1IiJuvWWwKMeta?.name ?? "admin-accounts-account_id-caller_ids-new",
    path: new1IiJuvWWwKMeta?.path ?? "new",
    meta: new1IiJuvWWwKMeta || {},
    alias: new1IiJuvWWwKMeta?.alias || [],
    redirect: new1IiJuvWWwKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsmD1v9BpXAlMeta?.name ?? undefined,
    meta: caller_idsmD1v9BpXAlMeta || {},
    alias: caller_idsmD1v9BpXAlMeta?.alias || [],
    redirect: caller_idsmD1v9BpXAlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/caller_ids.vue").then(m => m.default || m)
  },
  {
    name: editGzaeozFyPoMeta?.name ?? "admin-accounts-account_id-edit",
    path: editGzaeozFyPoMeta?.path ?? "edit",
    meta: editGzaeozFyPoMeta || {},
    alias: editGzaeozFyPoMeta?.alias || [],
    redirect: editGzaeozFyPoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexaeNsCeedwBMeta?.name ?? "admin-accounts-account_id",
    path: indexaeNsCeedwBMeta?.path ?? "",
    meta: indexaeNsCeedwBMeta || {},
    alias: indexaeNsCeedwBMeta?.alias || [],
    redirect: indexaeNsCeedwBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/index.vue").then(m => m.default || m)
  },
  {
    path: sms_maskings60ylaoC2w3Meta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93CT4G3iOpOZMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: edit0ffMTN0vEoMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id-edit",
    path: edit0ffMTN0vEoMeta?.path ?? "edit",
    meta: edit0ffMTN0vEoMeta || {},
    alias: edit0ffMTN0vEoMeta?.alias || [],
    redirect: edit0ffMTN0vEoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexRUpiiEdN3CMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id",
    path: indexRUpiiEdN3CMeta?.path ?? "",
    meta: indexRUpiiEdN3CMeta || {},
    alias: indexRUpiiEdN3CMeta?.alias || [],
    redirect: indexRUpiiEdN3CMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93CT4G3iOpOZMeta?.name ?? undefined,
    meta: _91sms_masking_id_93CT4G3iOpOZMeta || {},
    alias: _91sms_masking_id_93CT4G3iOpOZMeta?.alias || [],
    redirect: _91sms_masking_id_93CT4G3iOpOZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexcS1sSS9VrXMeta?.name ?? "admin-accounts-account_id-sms_maskings",
    path: indexcS1sSS9VrXMeta?.path ?? "",
    meta: indexcS1sSS9VrXMeta || {},
    alias: indexcS1sSS9VrXMeta?.alias || [],
    redirect: indexcS1sSS9VrXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newZeXuUqJmtoMeta?.name ?? "admin-accounts-account_id-sms_maskings-new",
    path: newZeXuUqJmtoMeta?.path ?? "new",
    meta: newZeXuUqJmtoMeta || {},
    alias: newZeXuUqJmtoMeta?.alias || [],
    redirect: newZeXuUqJmtoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskings60ylaoC2w3Meta?.name ?? undefined,
    meta: sms_maskings60ylaoC2w3Meta || {},
    alias: sms_maskings60ylaoC2w3Meta?.alias || [],
    redirect: sms_maskings60ylaoC2w3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id]/sms_maskings.vue").then(m => m.default || m)
  }
],
    name: _91account_id_93zL97nlTSgPMeta?.name ?? undefined,
    meta: _91account_id_93zL97nlTSgPMeta || {},
    alias: _91account_id_93zL97nlTSgPMeta?.alias || [],
    redirect: _91account_id_93zL97nlTSgPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/[account_id].vue").then(m => m.default || m)
  },
  {
    name: indexz2BQhfH574Meta?.name ?? "admin-accounts",
    path: indexz2BQhfH574Meta?.path ?? "",
    meta: indexz2BQhfH574Meta || {},
    alias: indexz2BQhfH574Meta?.alias || [],
    redirect: indexz2BQhfH574Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: wizard_plan_selectionCJdRfk5NS6Meta?.name ?? "admin-accounts-wizard_plan_selection",
    path: wizard_plan_selectionCJdRfk5NS6Meta?.path ?? "wizard_plan_selection",
    meta: wizard_plan_selectionCJdRfk5NS6Meta || {},
    alias: wizard_plan_selectionCJdRfk5NS6Meta?.alias || [],
    redirect: wizard_plan_selectionCJdRfk5NS6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/wizard_plan_selection.vue").then(m => m.default || m)
  },
  {
    name: wizarduWDuV77AwfMeta?.name ?? "admin-accounts-wizard",
    path: wizarduWDuV77AwfMeta?.path ?? "wizard",
    meta: wizarduWDuV77AwfMeta || {},
    alias: wizarduWDuV77AwfMeta?.alias || [],
    redirect: wizarduWDuV77AwfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts/wizard.vue").then(m => m.default || m)
  }
],
    name: accounts43vAzWsPjoMeta?.name ?? undefined,
    meta: accounts43vAzWsPjoMeta || {},
    alias: accounts43vAzWsPjoMeta?.alias || [],
    redirect: accounts43vAzWsPjoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    path: admin_usersqht8vQF9hgMeta?.path ?? "admin_users",
    children: [
  {
    path: _91admin_user_id_93MAG1NLG3mJMeta?.path ?? ":admin_user_id()",
    children: [
  {
    name: editZBTs0CA1lpMeta?.name ?? "admin-admin_users-admin_user_id-edit",
    path: editZBTs0CA1lpMeta?.path ?? "edit",
    meta: editZBTs0CA1lpMeta || {},
    alias: editZBTs0CA1lpMeta?.alias || [],
    redirect: editZBTs0CA1lpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/admin_users/[admin_user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexgPXirJ3FUFMeta?.name ?? "admin-admin_users-admin_user_id",
    path: indexgPXirJ3FUFMeta?.path ?? "",
    meta: indexgPXirJ3FUFMeta || {},
    alias: indexgPXirJ3FUFMeta?.alias || [],
    redirect: indexgPXirJ3FUFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/admin_users/[admin_user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91admin_user_id_93MAG1NLG3mJMeta?.name ?? undefined,
    meta: _91admin_user_id_93MAG1NLG3mJMeta || {},
    alias: _91admin_user_id_93MAG1NLG3mJMeta?.alias || [],
    redirect: _91admin_user_id_93MAG1NLG3mJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/admin_users/[admin_user_id].vue").then(m => m.default || m)
  },
  {
    name: indexDvAVAojSHlMeta?.name ?? "admin-admin_users",
    path: indexDvAVAojSHlMeta?.path ?? "",
    meta: indexDvAVAojSHlMeta || {},
    alias: indexDvAVAojSHlMeta?.alias || [],
    redirect: indexDvAVAojSHlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/admin_users/index.vue").then(m => m.default || m)
  },
  {
    name: newC9PVA46fntMeta?.name ?? "admin-admin_users-new",
    path: newC9PVA46fntMeta?.path ?? "new",
    meta: newC9PVA46fntMeta || {},
    alias: newC9PVA46fntMeta?.alias || [],
    redirect: newC9PVA46fntMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/admin_users/new.vue").then(m => m.default || m)
  }
],
    name: admin_usersqht8vQF9hgMeta?.name ?? undefined,
    meta: admin_usersqht8vQF9hgMeta || {},
    alias: admin_usersqht8vQF9hgMeta?.alias || [],
    redirect: admin_usersqht8vQF9hgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/admin_users.vue").then(m => m.default || m)
  },
  {
    path: audit_trailsAREzMvSVoWMeta?.path ?? "audit_trails",
    children: [
  {
    name: indexFRz96N49BhMeta?.name ?? "admin-audit_trails",
    path: indexFRz96N49BhMeta?.path ?? "",
    meta: indexFRz96N49BhMeta || {},
    alias: indexFRz96N49BhMeta?.alias || [],
    redirect: indexFRz96N49BhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/audit_trails/index.vue").then(m => m.default || m)
  }
],
    name: audit_trailsAREzMvSVoWMeta?.name ?? undefined,
    meta: audit_trailsAREzMvSVoWMeta || {},
    alias: audit_trailsAREzMvSVoWMeta?.alias || [],
    redirect: audit_trailsAREzMvSVoWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/audit_trails.vue").then(m => m.default || m)
  },
  {
    path: billingska0hKMyJLYMeta?.path ?? "billings",
    children: [
  {
    path: _91billing_id_93cD1pDiWQzeMeta?.path ?? ":billing_id()",
    children: [
  {
    name: editK0w6PiXzWMMeta?.name ?? "admin-billings-billing_id-edit",
    path: editK0w6PiXzWMMeta?.path ?? "edit",
    meta: editK0w6PiXzWMMeta || {},
    alias: editK0w6PiXzWMMeta?.alias || [],
    redirect: editK0w6PiXzWMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/billings/[billing_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index7g82aCKq4DMeta?.name ?? "admin-billings-billing_id",
    path: index7g82aCKq4DMeta?.path ?? "",
    meta: index7g82aCKq4DMeta || {},
    alias: index7g82aCKq4DMeta?.alias || [],
    redirect: index7g82aCKq4DMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/billings/[billing_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91billing_id_93cD1pDiWQzeMeta?.name ?? undefined,
    meta: _91billing_id_93cD1pDiWQzeMeta || {},
    alias: _91billing_id_93cD1pDiWQzeMeta?.alias || [],
    redirect: _91billing_id_93cD1pDiWQzeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/billings/[billing_id].vue").then(m => m.default || m)
  },
  {
    name: indexZ2WXFbR6WQMeta?.name ?? "admin-billings",
    path: indexZ2WXFbR6WQMeta?.path ?? "",
    meta: indexZ2WXFbR6WQMeta || {},
    alias: indexZ2WXFbR6WQMeta?.alias || [],
    redirect: indexZ2WXFbR6WQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/billings/index.vue").then(m => m.default || m)
  },
  {
    name: newM17IezlsDfMeta?.name ?? "admin-billings-new",
    path: newM17IezlsDfMeta?.path ?? "new",
    meta: newM17IezlsDfMeta || {},
    alias: newM17IezlsDfMeta?.alias || [],
    redirect: newM17IezlsDfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/billings/new.vue").then(m => m.default || m)
  }
],
    name: billingska0hKMyJLYMeta?.name ?? undefined,
    meta: billingska0hKMyJLYMeta || {},
    alias: billingska0hKMyJLYMeta?.alias || [],
    redirect: billingska0hKMyJLYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/billings.vue").then(m => m.default || m)
  },
  {
    path: caller_idsiF9lQbEqyoMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93Tlo82hWtwJMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: edit2wdY8BKNuBMeta?.name ?? "admin-caller_ids-caller_id_id-edit",
    path: edit2wdY8BKNuBMeta?.path ?? "edit",
    meta: edit2wdY8BKNuBMeta || {},
    alias: edit2wdY8BKNuBMeta?.alias || [],
    redirect: edit2wdY8BKNuBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNScgU5hiMhMeta?.name ?? "admin-caller_ids-caller_id_id",
    path: indexNScgU5hiMhMeta?.path ?? "",
    meta: indexNScgU5hiMhMeta || {},
    alias: indexNScgU5hiMhMeta?.alias || [],
    redirect: indexNScgU5hiMhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93Tlo82hWtwJMeta?.name ?? undefined,
    meta: _91caller_id_id_93Tlo82hWtwJMeta || {},
    alias: _91caller_id_id_93Tlo82hWtwJMeta?.alias || [],
    redirect: _91caller_id_id_93Tlo82hWtwJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexehfW2JzR83Meta?.name ?? "admin-caller_ids",
    path: indexehfW2JzR83Meta?.path ?? "",
    meta: indexehfW2JzR83Meta || {},
    alias: indexehfW2JzR83Meta?.alias || [],
    redirect: indexehfW2JzR83Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newZurUbi79kLMeta?.name ?? "admin-caller_ids-new",
    path: newZurUbi79kLMeta?.path ?? "new",
    meta: newZurUbi79kLMeta || {},
    alias: newZurUbi79kLMeta?.alias || [],
    redirect: newZurUbi79kLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsiF9lQbEqyoMeta?.name ?? undefined,
    meta: caller_idsiF9lQbEqyoMeta || {},
    alias: caller_idsiF9lQbEqyoMeta?.alias || [],
    redirect: caller_idsiF9lQbEqyoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/caller_ids.vue").then(m => m.default || m)
  },
  {
    path: _91campaign_setting_id_931eAwStTlkVMeta?.path ?? "campaign_settings/:campaign_setting_id()",
    children: [
  {
    name: editj07an19l4rMeta?.name ?? "admin-campaign_settings-campaign_setting_id-edit",
    path: editj07an19l4rMeta?.path ?? "edit",
    meta: editj07an19l4rMeta || {},
    alias: editj07an19l4rMeta?.alias || [],
    redirect: editj07an19l4rMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indextdbB1c3JALMeta?.name ?? "admin-campaign_settings-campaign_setting_id",
    path: indextdbB1c3JALMeta?.path ?? "",
    meta: indextdbB1c3JALMeta || {},
    alias: indextdbB1c3JALMeta?.alias || [],
    redirect: indextdbB1c3JALMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91campaign_setting_id_931eAwStTlkVMeta?.name ?? undefined,
    meta: _91campaign_setting_id_931eAwStTlkVMeta || {},
    alias: _91campaign_setting_id_931eAwStTlkVMeta?.alias || [],
    redirect: _91campaign_setting_id_931eAwStTlkVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaign_settings/[campaign_setting_id].vue").then(m => m.default || m)
  },
  {
    path: campaigns4NwP9xUop0Meta?.path ?? "campaigns",
    children: [
  {
    path: _91campaign_id_93y1e6RDEUVrMeta?.path ?? ":campaign_id()",
    children: [
  {
    name: edit2cMOHexdz3Meta?.name ?? "admin-campaigns-campaign_id-edit",
    path: edit2cMOHexdz3Meta?.path ?? "edit",
    meta: edit2cMOHexdz3Meta || {},
    alias: edit2cMOHexdz3Meta?.alias || [],
    redirect: edit2cMOHexdz3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index1pED0Gkfa6Meta?.name ?? "admin-campaigns-campaign_id",
    path: index1pED0Gkfa6Meta?.path ?? "",
    meta: index1pED0Gkfa6Meta || {},
    alias: index1pED0Gkfa6Meta?.alias || [],
    redirect: index1pED0Gkfa6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/index.vue").then(m => m.default || m)
  },
  {
    path: outbound_call_transactions2TP0b8dzk9Meta?.path ?? "outbound_call_transactions",
    children: [
  {
    path: _91outbound_call_transaction_id_93nwgc3kJjvpMeta?.path ?? ":outbound_call_transaction_id()",
    children: [
  {
    name: edit9xiaXqo2m7Meta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id-edit",
    path: edit9xiaXqo2m7Meta?.path ?? "edit",
    meta: edit9xiaXqo2m7Meta || {},
    alias: edit9xiaXqo2m7Meta?.alias || [],
    redirect: edit9xiaXqo2m7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexi28upBRk15Meta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id",
    path: indexi28upBRk15Meta?.path ?? "",
    meta: indexi28upBRk15Meta || {},
    alias: indexi28upBRk15Meta?.alias || [],
    redirect: indexi28upBRk15Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91outbound_call_transaction_id_93nwgc3kJjvpMeta?.name ?? undefined,
    meta: _91outbound_call_transaction_id_93nwgc3kJjvpMeta || {},
    alias: _91outbound_call_transaction_id_93nwgc3kJjvpMeta?.alias || [],
    redirect: _91outbound_call_transaction_id_93nwgc3kJjvpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue").then(m => m.default || m)
  },
  {
    name: index0Ft1oe2AeeMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions",
    path: index0Ft1oe2AeeMeta?.path ?? "",
    meta: index0Ft1oe2AeeMeta || {},
    alias: index0Ft1oe2AeeMeta?.alias || [],
    redirect: index0Ft1oe2AeeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue").then(m => m.default || m)
  },
  {
    name: newUA6CBYr28hMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-new",
    path: newUA6CBYr28hMeta?.path ?? "new",
    meta: newUA6CBYr28hMeta || {},
    alias: newUA6CBYr28hMeta?.alias || [],
    redirect: newUA6CBYr28hMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue").then(m => m.default || m)
  }
],
    name: outbound_call_transactions2TP0b8dzk9Meta?.name ?? undefined,
    meta: outbound_call_transactions2TP0b8dzk9Meta || {},
    alias: outbound_call_transactions2TP0b8dzk9Meta?.alias || [],
    redirect: outbound_call_transactions2TP0b8dzk9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue").then(m => m.default || m)
  }
],
    name: _91campaign_id_93y1e6RDEUVrMeta?.name ?? undefined,
    meta: _91campaign_id_93y1e6RDEUVrMeta || {},
    alias: _91campaign_id_93y1e6RDEUVrMeta?.alias || [],
    redirect: _91campaign_id_93y1e6RDEUVrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns/[campaign_id].vue").then(m => m.default || m)
  },
  {
    name: indexfTLaO2i2KAMeta?.name ?? "admin-campaigns",
    path: indexfTLaO2i2KAMeta?.path ?? "",
    meta: indexfTLaO2i2KAMeta || {},
    alias: indexfTLaO2i2KAMeta?.alias || [],
    redirect: indexfTLaO2i2KAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: newCnChjHchDOMeta?.name ?? "admin-campaigns-new",
    path: newCnChjHchDOMeta?.path ?? "new",
    meta: newCnChjHchDOMeta || {},
    alias: newCnChjHchDOMeta?.alias || [],
    redirect: newCnChjHchDOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns/new.vue").then(m => m.default || m)
  }
],
    name: campaigns4NwP9xUop0Meta?.name ?? undefined,
    meta: campaigns4NwP9xUop0Meta || {},
    alias: campaigns4NwP9xUop0Meta?.alias || [],
    redirect: campaigns4NwP9xUop0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/campaigns.vue").then(m => m.default || m)
  },
  {
    path: custom_emailsAGYXQkBb8FMeta?.path ?? "custom_emails",
    children: [
  {
    path: _91custom_email_id_93TXzmteGZ4jMeta?.path ?? ":custom_email_id()",
    children: [
  {
    name: indexavs9oMYocYMeta?.name ?? "admin-custom_emails-custom_email_id",
    path: indexavs9oMYocYMeta?.path ?? "",
    meta: indexavs9oMYocYMeta || {},
    alias: indexavs9oMYocYMeta?.alias || [],
    redirect: indexavs9oMYocYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/custom_emails/[custom_email_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91custom_email_id_93TXzmteGZ4jMeta?.name ?? undefined,
    meta: _91custom_email_id_93TXzmteGZ4jMeta || {},
    alias: _91custom_email_id_93TXzmteGZ4jMeta?.alias || [],
    redirect: _91custom_email_id_93TXzmteGZ4jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/custom_emails/[custom_email_id].vue").then(m => m.default || m)
  },
  {
    name: indexyvfYgxKlHYMeta?.name ?? "admin-custom_emails",
    path: indexyvfYgxKlHYMeta?.path ?? "",
    meta: indexyvfYgxKlHYMeta || {},
    alias: indexyvfYgxKlHYMeta?.alias || [],
    redirect: indexyvfYgxKlHYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/custom_emails/index.vue").then(m => m.default || m)
  },
  {
    name: new3bY7R1D5HGMeta?.name ?? "admin-custom_emails-new",
    path: new3bY7R1D5HGMeta?.path ?? "new",
    meta: new3bY7R1D5HGMeta || {},
    alias: new3bY7R1D5HGMeta?.alias || [],
    redirect: new3bY7R1D5HGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/custom_emails/new.vue").then(m => m.default || m)
  }
],
    name: custom_emailsAGYXQkBb8FMeta?.name ?? undefined,
    meta: custom_emailsAGYXQkBb8FMeta || {},
    alias: custom_emailsAGYXQkBb8FMeta?.alias || [],
    redirect: custom_emailsAGYXQkBb8FMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/custom_emails.vue").then(m => m.default || m)
  },
  {
    path: dashboard4DsrY0lSsPMeta?.path ?? "dashboard",
    children: [
  {
    name: indexErfkS6Xyf3Meta?.name ?? "admin-dashboard",
    path: indexErfkS6Xyf3Meta?.path ?? "",
    meta: indexErfkS6Xyf3Meta || {},
    alias: indexErfkS6Xyf3Meta?.alias || [],
    redirect: indexErfkS6Xyf3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/dashboard/index.vue").then(m => m.default || m)
  }
],
    name: dashboard4DsrY0lSsPMeta?.name ?? undefined,
    meta: dashboard4DsrY0lSsPMeta || {},
    alias: dashboard4DsrY0lSsPMeta?.alias || [],
    redirect: dashboard4DsrY0lSsPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    path: inquiriesODlUzL3xmRMeta?.path ?? "inquiries",
    children: [
  {
    path: _91inquiry_id_93GREGRVoXLTMeta?.path ?? ":inquiry_id()",
    children: [
  {
    name: indexMDiqtlKhIEMeta?.name ?? "admin-inquiries-inquiry_id",
    path: indexMDiqtlKhIEMeta?.path ?? "",
    meta: indexMDiqtlKhIEMeta || {},
    alias: indexMDiqtlKhIEMeta?.alias || [],
    redirect: indexMDiqtlKhIEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/inquiries/[inquiry_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91inquiry_id_93GREGRVoXLTMeta?.name ?? undefined,
    meta: _91inquiry_id_93GREGRVoXLTMeta || {},
    alias: _91inquiry_id_93GREGRVoXLTMeta?.alias || [],
    redirect: _91inquiry_id_93GREGRVoXLTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/inquiries/[inquiry_id].vue").then(m => m.default || m)
  },
  {
    name: indexi9HZdp7Pe9Meta?.name ?? "admin-inquiries",
    path: indexi9HZdp7Pe9Meta?.path ?? "",
    meta: indexi9HZdp7Pe9Meta || {},
    alias: indexi9HZdp7Pe9Meta?.alias || [],
    redirect: indexi9HZdp7Pe9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/inquiries/index.vue").then(m => m.default || m)
  },
  {
    name: new0Sj5txG8pgMeta?.name ?? "admin-inquiries-new",
    path: new0Sj5txG8pgMeta?.path ?? "new",
    meta: new0Sj5txG8pgMeta || {},
    alias: new0Sj5txG8pgMeta?.alias || [],
    redirect: new0Sj5txG8pgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/inquiries/new.vue").then(m => m.default || m)
  }
],
    name: inquiriesODlUzL3xmRMeta?.name ?? undefined,
    meta: inquiriesODlUzL3xmRMeta || {},
    alias: inquiriesODlUzL3xmRMeta?.alias || [],
    redirect: inquiriesODlUzL3xmRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/inquiries.vue").then(m => m.default || m)
  },
  {
    path: loginFVSAVHFuvzMeta?.path ?? "login",
    children: [
  {
    name: indexKB1gwfxadQMeta?.name ?? "admin-login",
    path: indexKB1gwfxadQMeta?.path ?? "",
    meta: indexKB1gwfxadQMeta || {},
    alias: indexKB1gwfxadQMeta?.alias || [],
    redirect: indexKB1gwfxadQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/login/index.vue").then(m => m.default || m)
  }
],
    name: loginFVSAVHFuvzMeta?.name ?? undefined,
    meta: loginFVSAVHFuvzMeta || {},
    alias: loginFVSAVHFuvzMeta?.alias || [],
    redirect: loginFVSAVHFuvzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: indexinTpGHWtBUMeta?.name ?? "admin-password-edit",
    path: indexinTpGHWtBUMeta?.path ?? "password/edit",
    meta: indexinTpGHWtBUMeta || {},
    alias: indexinTpGHWtBUMeta?.alias || [],
    redirect: indexinTpGHWtBUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: index718PKDUOXRMeta?.name ?? "admin-password-new",
    path: index718PKDUOXRMeta?.path ?? "password/new",
    meta: index718PKDUOXRMeta || {},
    alias: index718PKDUOXRMeta?.alias || [],
    redirect: index718PKDUOXRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/password/new/index.vue").then(m => m.default || m)
  },
  {
    path: reportsXRe5Walx7NMeta?.path ?? "reports",
    children: [
  {
    name: indextWcq4V2BwwMeta?.name ?? "admin-reports",
    path: indextWcq4V2BwwMeta?.path ?? "",
    meta: indextWcq4V2BwwMeta || {},
    alias: indextWcq4V2BwwMeta?.alias || [],
    redirect: indextWcq4V2BwwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/reports/index.vue").then(m => m.default || m)
  }
],
    name: reportsXRe5Walx7NMeta?.name ?? undefined,
    meta: reportsXRe5Walx7NMeta || {},
    alias: reportsXRe5Walx7NMeta?.alias || [],
    redirect: reportsXRe5Walx7NMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsPly2vgOXYqMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93z9XTqBL614Meta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editlrafUvgbLjMeta?.name ?? "admin-sms_maskings-sms_masking_id-edit",
    path: editlrafUvgbLjMeta?.path ?? "edit",
    meta: editlrafUvgbLjMeta || {},
    alias: editlrafUvgbLjMeta?.alias || [],
    redirect: editlrafUvgbLjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexiT0YppvMvAMeta?.name ?? "admin-sms_maskings-sms_masking_id",
    path: indexiT0YppvMvAMeta?.path ?? "",
    meta: indexiT0YppvMvAMeta || {},
    alias: indexiT0YppvMvAMeta?.alias || [],
    redirect: indexiT0YppvMvAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93z9XTqBL614Meta?.name ?? undefined,
    meta: _91sms_masking_id_93z9XTqBL614Meta || {},
    alias: _91sms_masking_id_93z9XTqBL614Meta?.alias || [],
    redirect: _91sms_masking_id_93z9XTqBL614Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: index5htBCm7AiUMeta?.name ?? "admin-sms_maskings",
    path: index5htBCm7AiUMeta?.path ?? "",
    meta: index5htBCm7AiUMeta || {},
    alias: index5htBCm7AiUMeta?.alias || [],
    redirect: index5htBCm7AiUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newExJaEVOHFhMeta?.name ?? "admin-sms_maskings-new",
    path: newExJaEVOHFhMeta?.path ?? "new",
    meta: newExJaEVOHFhMeta || {},
    alias: newExJaEVOHFhMeta?.alias || [],
    redirect: newExJaEVOHFhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsPly2vgOXYqMeta?.name ?? undefined,
    meta: sms_maskingsPly2vgOXYqMeta || {},
    alias: sms_maskingsPly2vgOXYqMeta?.alias || [],
    redirect: sms_maskingsPly2vgOXYqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/sms_maskings.vue").then(m => m.default || m)
  },
  {
    path: usersMEnkxFbViQMeta?.path ?? "users",
    children: [
  {
    path: _91user_id_93ZWcnhvUr1MMeta?.path ?? ":user_id()",
    children: [
  {
    name: editBuq5KvwJ3LMeta?.name ?? "admin-users-user_id-edit",
    path: editBuq5KvwJ3LMeta?.path ?? "edit",
    meta: editBuq5KvwJ3LMeta || {},
    alias: editBuq5KvwJ3LMeta?.alias || [],
    redirect: editBuq5KvwJ3LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexPdiUbJvjHRMeta?.name ?? "admin-users-user_id",
    path: indexPdiUbJvjHRMeta?.path ?? "",
    meta: indexPdiUbJvjHRMeta || {},
    alias: indexPdiUbJvjHRMeta?.alias || [],
    redirect: indexPdiUbJvjHRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/users/[user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91user_id_93ZWcnhvUr1MMeta?.name ?? undefined,
    meta: _91user_id_93ZWcnhvUr1MMeta || {},
    alias: _91user_id_93ZWcnhvUr1MMeta?.alias || [],
    redirect: _91user_id_93ZWcnhvUr1MMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/users/[user_id].vue").then(m => m.default || m)
  },
  {
    name: indexEOfeHYUwqvMeta?.name ?? "admin-users",
    path: indexEOfeHYUwqvMeta?.path ?? "",
    meta: indexEOfeHYUwqvMeta || {},
    alias: indexEOfeHYUwqvMeta?.alias || [],
    redirect: indexEOfeHYUwqvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: newHO4yxMqFOTMeta?.name ?? "admin-users-new",
    path: newHO4yxMqFOTMeta?.path ?? "new",
    meta: newHO4yxMqFOTMeta || {},
    alias: newHO4yxMqFOTMeta?.alias || [],
    redirect: newHO4yxMqFOTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/users/new.vue").then(m => m.default || m)
  }
],
    name: usersMEnkxFbViQMeta?.name ?? undefined,
    meta: usersMEnkxFbViQMeta || {},
    alias: usersMEnkxFbViQMeta?.alias || [],
    redirect: usersMEnkxFbViQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    path: _91wallet_id_9323WxlGHjSgMeta?.path ?? "wallets/:wallet_id()",
    children: [
  {
    name: editayM5BkhzxrMeta?.name ?? "admin-wallets-wallet_id-edit",
    path: editayM5BkhzxrMeta?.path ?? "edit",
    meta: editayM5BkhzxrMeta || {},
    alias: editayM5BkhzxrMeta?.alias || [],
    redirect: editayM5BkhzxrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/wallets/[wallet_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexWIAl2euehoMeta?.name ?? "admin-wallets-wallet_id",
    path: indexWIAl2euehoMeta?.path ?? "",
    meta: indexWIAl2euehoMeta || {},
    alias: indexWIAl2euehoMeta?.alias || [],
    redirect: indexWIAl2euehoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/wallets/[wallet_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91wallet_id_9323WxlGHjSgMeta?.name ?? undefined,
    meta: _91wallet_id_9323WxlGHjSgMeta || {},
    alias: _91wallet_id_9323WxlGHjSgMeta?.alias || [],
    redirect: _91wallet_id_9323WxlGHjSgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin/wallets/[wallet_id].vue").then(m => m.default || m)
  }
],
    meta: adminIiZNN2RmL5Meta || {},
    alias: adminIiZNN2RmL5Meta?.alias || [],
    redirect: adminIiZNN2RmL5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indexCmtRyZgr58Meta?.name ?? "audit_logs",
    path: indexCmtRyZgr58Meta?.path ?? "/audit_logs",
    meta: indexCmtRyZgr58Meta || {},
    alias: indexCmtRyZgr58Meta?.alias || [],
    redirect: indexCmtRyZgr58Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/audit_logs/index.vue").then(m => m.default || m)
  },
  {
    name: indexqeKWwUHoruMeta?.name ?? "billings",
    path: indexqeKWwUHoruMeta?.path ?? "/billings",
    meta: indexqeKWwUHoruMeta || {},
    alias: indexqeKWwUHoruMeta?.alias || [],
    redirect: indexqeKWwUHoruMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/billings/index.vue").then(m => m.default || m)
  },
  {
    name: indexzSxOk88BW4Meta?.name ?? "call_out-reports",
    path: indexzSxOk88BW4Meta?.path ?? "/call_out/reports",
    meta: indexzSxOk88BW4Meta || {},
    alias: indexzSxOk88BW4Meta?.alias || [],
    redirect: indexzSxOk88BW4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/call_out/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wo5mslhZKJMeta?.name ?? "call_recordings-id",
    path: _91id_93wo5mslhZKJMeta?.path ?? "/call_recordings/:id()",
    meta: _91id_93wo5mslhZKJMeta || {},
    alias: _91id_93wo5mslhZKJMeta?.alias || [],
    redirect: _91id_93wo5mslhZKJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/call_recordings/[id].vue").then(m => m.default || m)
  },
  {
    name: indexLOdCBfJ2lCMeta?.name ?? "campaigns-id",
    path: indexLOdCBfJ2lCMeta?.path ?? "/campaigns/:id()",
    meta: indexLOdCBfJ2lCMeta || {},
    alias: indexLOdCBfJ2lCMeta?.alias || [],
    redirect: indexLOdCBfJ2lCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/campaigns/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexv8l5Wki73GMeta?.name ?? "campaigns",
    path: indexv8l5Wki73GMeta?.path ?? "/campaigns",
    meta: indexv8l5Wki73GMeta || {},
    alias: indexv8l5Wki73GMeta?.alias || [],
    redirect: indexv8l5Wki73GMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnbMqLTrIkMeta?.name ?? "dashboard",
    path: indexwnbMqLTrIkMeta?.path ?? "/dashboard",
    meta: indexwnbMqLTrIkMeta || {},
    alias: indexwnbMqLTrIkMeta?.alias || [],
    redirect: indexwnbMqLTrIkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: index6V6nWvmrxOMeta?.name ?? "help",
    path: index6V6nWvmrxOMeta?.path ?? "/help",
    meta: index6V6nWvmrxOMeta || {},
    alias: index6V6nWvmrxOMeta?.alias || [],
    redirect: index6V6nWvmrxOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexSutvlBJwRXMeta?.name ?? "index",
    path: indexSutvlBJwRXMeta?.path ?? "/",
    meta: indexSutvlBJwRXMeta || {},
    alias: indexSutvlBJwRXMeta?.alias || [],
    redirect: indexSutvlBJwRXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexv58wIZBvwVMeta?.name ?? "inquiry",
    path: indexv58wIZBvwVMeta?.path ?? "/inquiry",
    meta: indexv58wIZBvwVMeta || {},
    alias: indexv58wIZBvwVMeta?.alias || [],
    redirect: indexv58wIZBvwVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: editor5z45V7c4cHMeta?.name ?? "ivr_trees-id-editor",
    path: editor5z45V7c4cHMeta?.path ?? "/ivr_trees/:id()/editor",
    meta: editor5z45V7c4cHMeta || {},
    alias: editor5z45V7c4cHMeta?.alias || [],
    redirect: editor5z45V7c4cHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/ivr_trees/[id]/editor.vue").then(m => m.default || m)
  },
  {
    name: indexwRPYZxolMSMeta?.name ?? "ivr_trees",
    path: indexwRPYZxolMSMeta?.path ?? "/ivr_trees",
    meta: indexwRPYZxolMSMeta || {},
    alias: indexwRPYZxolMSMeta?.alias || [],
    redirect: indexwRPYZxolMSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/ivr_trees/index.vue").then(m => m.default || m)
  },
  {
    name: verify_emailqXs5heqhs9Meta?.name ?? "profiles-user_id-verify_email",
    path: verify_emailqXs5heqhs9Meta?.path ?? "/profiles/:user_id()/verify_email",
    meta: verify_emailqXs5heqhs9Meta || {},
    alias: verify_emailqXs5heqhs9Meta?.alias || [],
    redirect: verify_emailqXs5heqhs9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/profiles/[user_id]/verify_email.vue").then(m => m.default || m)
  },
  {
    name: welcome_dashboardcQeN7LjUYpMeta?.name ?? "profiles-user_id-welcome_dashboard",
    path: welcome_dashboardcQeN7LjUYpMeta?.path ?? "/profiles/:user_id()/welcome_dashboard",
    meta: welcome_dashboardcQeN7LjUYpMeta || {},
    alias: welcome_dashboardcQeN7LjUYpMeta?.alias || [],
    redirect: welcome_dashboardcQeN7LjUYpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/profiles/[user_id]/welcome_dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexrKNGUodBNAMeta?.name ?? "profiles",
    path: indexrKNGUodBNAMeta?.path ?? "/profiles",
    meta: indexrKNGUodBNAMeta || {},
    alias: indexrKNGUodBNAMeta?.alias || [],
    redirect: indexrKNGUodBNAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: indexSqXg3DTXs7Meta?.name ?? "sub_users-users",
    path: indexSqXg3DTXs7Meta?.path ?? "/sub_users/users",
    meta: indexSqXg3DTXs7Meta || {},
    alias: indexSqXg3DTXs7Meta?.alias || [],
    redirect: indexSqXg3DTXs7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/sub_users/users/index.vue").then(m => m.default || m)
  },
  {
    name: index08ZD9vakJiMeta?.name ?? "terms_and_conditions",
    path: index08ZD9vakJiMeta?.path ?? "/terms_and_conditions",
    meta: index08ZD9vakJiMeta || {},
    alias: index08ZD9vakJiMeta?.alias || [],
    redirect: index08ZD9vakJiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/terms_and_conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexUzUovQtZf4Meta?.name ?? "users-password-edit",
    path: indexUzUovQtZf4Meta?.path ?? "/users/password/edit",
    meta: indexUzUovQtZf4Meta || {},
    alias: indexUzUovQtZf4Meta?.alias || [],
    redirect: indexUzUovQtZf4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/users/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexJAV1573BcIMeta?.name ?? "users-password-new",
    path: indexJAV1573BcIMeta?.path ?? "/users/password/new",
    meta: indexJAV1573BcIMeta || {},
    alias: indexJAV1573BcIMeta?.alias || [],
    redirect: indexJAV1573BcIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/users/password/new/index.vue").then(m => m.default || m)
  },
  {
    name: index4HqjW9FzuJMeta?.name ?? "users-sign_in",
    path: index4HqjW9FzuJMeta?.path ?? "/users/sign_in",
    meta: index4HqjW9FzuJMeta || {},
    alias: index4HqjW9FzuJMeta?.alias || [],
    redirect: index4HqjW9FzuJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src538496046/src/pages/users/sign_in/index.vue").then(m => m.default || m)
  }
]