import validate from "/codebuild/output/src457679548/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/codebuild/output/src457679548/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/codebuild/output/src457679548/src/middleware/admin.js"),
  auth: () => import("/codebuild/output/src457679548/src/middleware/auth.js"),
  "disable-page": () => import("/codebuild/output/src457679548/src/middleware/disable-page.js"),
  unauth: () => import("/codebuild/output/src457679548/src/middleware/unauth.js")
}