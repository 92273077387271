import { z } from "zod"

const isValidISODateTime = (value) => !isNaN(Date.parse(value))

export const Request = {
        PreLogin: z.object({
                email: z.string(),
                password: z.string(),
                g_recaptcha_response: z.string(),
        }).safeParse,
        PreLoginAdmin: z.object({
                email: z.string(),
                password: z.string(),
                g_recaptcha_response: z.string(),
                is_admin: z.boolean(),
        }).safeParse,
        SignIn: z.object({
                user: z.object({
                        otp_code: z.string(),
                        g_recaptcha_response: z.string(),
                        email: z.string(),
                }),
        }).safeParse,
        AdminSignIn: z.object({
                admin_user: z.object({
                        otp: z.string(),
                        email: z.string(),
                        is_admin: z.boolean(),
                }),
        }).safeParse,
        ResendOtp: z.object({
                email: z.string(),
                // otp_secret_key: z.string(),
        }).safeParse,
        ResendOtpAdmin: z.object({
                email: z.string(),
                is_admin: z.boolean(),
        }).safeParse,
        ForgotPassword: z.object({
                user: z.object({
                        email: z.string(),
                }),
        }).safeParse,
        SetPassword: z.object({
                user: z.object({
                        reset_password_token: z.string(),
                        password: z.string(),
                        password_confirmation: z.string(),
                }),
        }).safeParse,
        ForgotPasswordAdmin: z.object({
                admin_user: z.object({
                        email: z.string(),
                }),
        }).safeParse,
        SetPasswordAdmin: z.object({
                admin_user: z.object({
                        reset_password_token: z.string(),
                        password: z.string(),
                        password_confirmation: z.string(),
                }),
        }).safeParse,
}

export const Response = {
        SignIn: z.object({
                id: z.number(),
                account_id: z.number(),
                email: z.string(),
                mobile: z.string(),
                first_name: z.string(),
                last_name: z.string(),
                role_id: z.number(),
                status: z.number(),
                user_is_dms_enabled: z.boolean(),
                access_rights: z.object({
                        module: z.string(),
                        permission: z.string(),
                }).array(),
                role_name: z.enum([
                        "admin",
                        "client",
                        "sub user",
                ]),
        }).safeParse,
        AdminSignIn: z.object({
                id: z.number(),
                created_at: z
                  .string()
                  .refine(isValidISODateTime, { message: "Invalid datetime format" }),
                current_sign_in_token: z.string(),
                email: z.string().email(),
                first_name: z.string(),
                last_name: z.string(),
                last_otp_at: z.number(), // Unix timestamp
                otp: z.string(),
                otp_expiration: z
                  .string()
                  .refine(isValidISODateTime, { message: "Invalid datetime format" }),
                otp_secret: z.string(),
                otp_secret_key: z.string(),
                updated_at: z
                  .string()
                  .refine(isValidISODateTime, { message: "Invalid datetime format" }),
        }).safeParse,
        PreLogin: z.object({
                email: z.string(),
                // otp_secret_key: z.string(),
        }).safeParse,
        PreLoginAdmin: z.object({
                email: z.string(),
                // otp_secret_key: z.string(),
        }).safeParse,
        ValidateOtp: z.object({
                email: z.string(),
                // otp_secret_key: z.string(),
        }).safeParse,
        ValidateOtpAdmin: z.object({
                email: z.string(),
                // otp_secret_key: z.string(),
        }).safeParse,
}

export const Error = {
        SignIn: z.object({
                error: z.string(),
        }).safeParse,
        AdminSignIn: z.object({
                error: z.string(),
        }).safeParse,
        PreLogin: z.object({
                error: z.string(),
        }).safeParse,
        PreLoginAdmin: z.object({
                error: z.string(),
        }).safeParse,
        ValidateOtp: z.object({
                error: z.string(),
        }).safeParse,
        ValidateOtpAdmin: z.object({
                error: z.string(),
        }).safeParse,
}
