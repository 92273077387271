<template>
        <div id="creators">
                <!-- TODO(aes)
                / ~~~~~~~~~~~~~~~~~~~ Campaign Modal ~~~~~~~~~~~~~~~~~~~
                = DONE render 'shared/campaign_modal'
                / ~~~~~~~~~~~~~~~~~~~ Campaign Create Success ~~~~~~~~~~~~~~~~~~~
                = DONE render 'shared/create_success'
                / ~~~~~~~~~~~~~~~~~~~ IVR Tree Modal ~~~~~~~~~~~~~~~~~~~
                = DONE render 'shared/new_ivr'
                = render 'shared/new_ivr_limit'
                / ~~~~~~~~~~~~~~~~~~~ Select IVR Modal for Create Campaign ~~~~~~~~~~~~~~~~~~~
                = DONE render 'shared/select_ivr'
                -->
                <SharedCampaignModal v-model:campaign="campaign" :for_edit="for_edit" :ivr_name="ivr_name"
                        :disabled-dates="disabledDates" :set-blast-date="setBlastDate" :set-blast-time="setBlastTime"
                        :set-retry-blast-time="setRetryBlastTime" :unanswered-opt="unansweredOpt" :busy-opt="busyOpt"
                        :unreachable-opt="unreachableOpt" :handle-file-upload="handleFileUpload" :gray-btn="grayBtn"
                        :show_errors="show_errors" :errors="errors" @submit="onCampaignSubmit" />
                <SharedCreateSuccess :campaign="campaign" @view-ivr-tree="viewIvrTree" @view-campaign="viewCampaign" />
                <SharedNewIvr v-model:ivr_name="ivr_name" v-model:caller_id="caller_id"
                        v-model:non_globe_caller_id="non_globe_caller_id" v-model:sms_masking="sms_masking"
                        v-model:voice="voice" :caller_id_select_options="caller_id_select_options"
                        :non_globe_caller_id_select_options="non_globe_caller_id_select_options"
                        :sms_masking_for_listing="sms_masking_for_listing" :voices="voices" :show_errors="show_errors"
                        :errors="errors" @check-submit-button-ivr="checkSubmitButtonIvr" @create-ivr-tree="createIvrTree" />
                <SharedSelectIvr :name="name" :campaign="campaign" :ivr_trees="ivr_trees" :get-voice="getVoice"
                        :for_edit="for_edit" :ivr_trees_is_loading="ivr_trees_is_loading" :ivr-variables="ivrVariables"
                        :retrieve-variable-format="retrieveVariableFormat" :activate-button="activateButton"
                        :next-ivr-clickable="nextIvrClickable" :selected_ivr="selected_ivr" />
        </div>
</template>

<script>
import { init as initCaleandar } from "~/assets/js/vendor/caleandar"

/**
 * source: app/assets/javascript/call_out/creators.js.erb
 */

// TODO(aes): is this still needed
// axios.defaults.headers.common = {
//   'authenticity_token': document.querySelector('meta[name="csrf-token"]') && document.querySelector('meta[name="csrf-token"]').getAttribute('content')
// };

var yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
let currentDate = new Date();

var creators = {
        // el: '#creators',
        data() {
                return {
                        disabledDates: {
                                predicate: (date) => date <= yesterday
                        },
                        has_file: false,
                        selected_ivr: '',
                        created_ivr_id: '',
                        errors: [],
                        show_errors: false,
                        ivr_name: '',
                        ivr_tree_group_id: '',
                        ivr_tree_group_name: '',
                        name: '',
                        blast_date: '',
                        blast_time: '',
                        variables: '',
                        caller_id: $('#ivr_tree_caller_id').val(),
                        non_globe_caller_id: $('#ivr_tree_non_globe_caller_id').val(),
                        sms_masking: 'IVES',
                        voice: '',
                        ivr_template: $("input[name='ivr_tree[ivr_template]']:checked").val(),
                        campaigns: {
                                data: [],
                                total_items: 0,
                                per_page: 2,
                                from: 1,
                                to: 0,
                                current_page: 1,
                                page_total: 0
                        },
                        ivr_tree_id: '',
                        file: '',
                        ivr_trees: { data: [] },
                        ivr_tree: '',
                        for_edit: false,
                        ivr_trees_is_loading: false,
                        show_campaign_list: true,
                        is_loading: true,
                        show_campaign: true,
                        caleandar_data: {},
                        campaign: {
                                id: '',
                                name: '',
                                campaign_code: '',
                                blast_date: '',
                                blast_at: {
                                        hh: "hh",
                                        mm: "mm",
                                        A: 'A'
                                },
                                blast_attempts: [
                                        {
                                                blast_at: {
                                                        hh: "hh",
                                                        mm: "mm",
                                                        A: 'A'
                                                },
                                                status_filter: []
                                        }, {
                                                blast_at: {
                                                        hh: "hh",
                                                        mm: "mm",
                                                        A: 'A'
                                                },
                                                status_filter: []
                                        }
                                ],
                                isTimeEnable: false,
                                unanswered: false,
                                busy: false,
                                unreachable: false,
                                ivr_tree_id: '',
                                file: '',
                                ivr_status: '',
                                campaign_record_count: '',
                                ivr_tree_name: '',
                                filename: ''
                        },
                        max_ivr_tree: '',
                        selectedDate: '',
                        filterSelectedDate: '',
                        caller_id_select_options: [],
                        non_globe_caller_id_select_options: [],
                        sms_masking_for_listing: [],
                        voices: [],
                        time: '',
                }
        },
        methods: {
                showNetworkBlockDisclaimer() {
                        alert("This number may be blocked by other Networks. We recommend to use the random caller ID option.")
                },

                checkSubmitButtonIvr(e) {

                        if (e && e.target.id == 'ivr_tree_non_globe_caller_id' && e.target.value != 'random') { this.showNetworkBlockDisclaimer() }
                        let templateVal = $("input[name='ivr_tree[ivr_template]']:checked").val();
                        let nameVal = $('#ivr_tree_name').val();
                        let callerIdVal = this.caller_id;
                        let nonGlobecallerIdVal = this.non_globe_caller_id;
                        let smsMaskingVal = this.sms_masking;
                        let voiceVal = this.voice;

                        console.log('checkSubmitButtonIvr ***');
                        console.log(nameVal);
                        console.log(callerIdVal);
                        console.log(nonGlobecallerIdVal);
                        console.log(smsMaskingVal);
                        console.log(voiceVal);
                        console.log(templateVal);

                        const isDmsEnabled = window._utilities.isDmsEnabled()
                        if (templateVal &&
                                nameVal &&
                                callerIdVal &&
                                smsMaskingVal &&
                                voiceVal &&
                                nonGlobecallerIdVal &&
                                (!isDmsEnabled || (isDmsEnabled && this.ivr_tree_group_id))) {

                                this.name = nameVal;
                                this.caller_id = callerIdVal;
                                this.sms_masking = smsMaskingVal;
                                this.voice = voiceVal;
                                this.ivr_template = templateVal;

                                $('#new_ivr_create').removeClass('gray-btn').addClass('blue-btn');
                                $('#new_ivr_create').attr('disabled', false);
                        } else {
                                $('#new_ivr_create').removeClass('blue-btn').addClass('gray-btn');
                                $('#new_ivr_create').attr('disabled', true);
                        }
                },

                setBlastDate(date) {
                        this.campaign.blast_date = date;
                        this.checkSubmitButton();
                },

                setBlastTime(evt) {
                        if (evt != undefined) {
                                let timeData = evt.data;
                                if (timeData != undefined) {
                                        let timeH = timeData.hh;
                                        let timeM = timeData.mm;
                                        let timeA = timeData.A;

                                        this.campaign.blast_attempts[0].blast_at = timeH + ':' + timeM + ' ' + timeA;

                                        this.time = this.campaign.blast_attempts[0].blast_at
                                }
                        } else {
                                validTime = regexp.test(this.campaign.blast_attempts[0].blast_at);
                                if (validTime) {
                                        this.campaign.blast_attempts[0].blast_at = this.time;
                                        if (this.for_edit && this.campaign.blast_attempts[0].blast_at) { this.has_file = true; }
                                }
                        }
                        this.checkSubmitButton();
                },
                unansweredOpt() {
                        this.campaign.unanswered = !this.campaign.unanswered
                        if (this.campaign.unanswered == true) {
                                this.campaign.blast_attempts[1].status_filter.push('3')
                                this.campaign.isTimeEnable = true
                        } else {
                                let arr = this.campaign.blast_attempts[1].status_filter
                                let index = arr.indexOf('3');
                                if (index > -1) {
                                        this.campaign.blast_attempts[1].status_filter.splice(index, 1);
                                }
                        }
                        if (this.campaign.unanswered == true || this.campaign.busy == true || this.campaign.unreachable == true) {
                                this.campaign.isTimeEnable = true
                        } else {
                                this.campaign.isTimeEnable = false
                                $('.retry-attemps #timepicker').val('');
                        }
                },
                busyOpt() {
                        this.campaign.busy = !this.campaign.busy
                        if (this.campaign.busy == true) {
                                this.campaign.blast_attempts[1].status_filter.push('4')
                                this.campaign.isTimeEnable = true
                        } else {
                                let arr = this.campaign.blast_attempts[1].status_filter
                                let index = arr.indexOf('4');
                                if (index > -1) {
                                        this.campaign.blast_attempts[1].status_filter.splice(index, 1);
                                }
                        }
                        if (this.campaign.unanswered == true || this.campaign.busy == true || this.campaign.unreachable == true) {
                                this.campaign.isTimeEnable = true
                        } else {
                                this.campaign.isTimeEnable = false
                                $('.retry-attemps #timepicker').val('');
                        }
                },
                unreachableOpt() {
                        this.campaign.unreachable = !this.campaign.unreachable
                        if (this.campaign.unreachable == true) {
                                this.campaign.blast_attempts[1].status_filter.push('6')
                                this.campaign.isTimeEnable = true
                        } else {
                                let arr = this.campaign.blast_attempts[1].status_filter
                                let index = arr.indexOf('6');
                                if (index > -1) {
                                        this.campaign.blast_attempts[1].status_filter.splice(index, 1);
                                }
                        }
                        if (this.campaign.unanswered == true || this.campaign.busy == true || this.campaign.unreachable == true) {
                                this.campaign.isTimeEnable = true
                        } else {
                                this.campaign.isTimeEnable = false
                                $('.retry-attemps #timepicker').val('');
                        }
                },


                setRetryBlastTime(evt) {
                        if (evt != undefined) {
                                let timeData = evt.data;
                                if (timeData != undefined) {
                                        let timeH = timeData.hh;
                                        let timeM = timeData.mm;
                                        let timeA = timeData.A;
                                        this.campaign.blast_attempts[1].blast_at = timeH + ':' + timeM + ' ' + timeA;
                                        this.time = this.campaign.blast_attempts[1].blast_at
                                }
                        } else {
                                validTime = regexp.test(this.campaign.blast_attempts[1].blast_at);
                                if (validTime) {
                                        this.campaign.blast_attempts[1].blast_at = this.time;
                                        if (this.for_edit && this.campaign.blast_attempts[1].blast_at) { this.has_file = true; }
                                }
                        }
                        this.checkSubmitButton();
                },

                setCampaignName(e) {
                        this.campaign.name = e.target.value;
                        if (this.for_edit && this.campaign.name) { this.has_file = true; }
                        this.checkSubmitButton();
                },

                checkSubmitButton() {
                        let validTime = false;
                        let filename = false;


                        let regexp = /([0-9]|[0-9]):[0-9][0-9][ ][P|A]M/g;
                        // NOTE(aes): error here is expected when editing campaign for some reason
                        validTime = regexp.test(this.campaign.blast_attempts[0].blast_at);

                        let name = this.campaign.name;
                        let schedule = this.campaign.blast_date; //$("#campaign-schedule").val();
                        let time = this.campaign.blast_attempts[0].blast_at;
                        let file = this.campaign.file;
                        let hasFile = this.has_file;
                        this.campaign.name = name;
                        this.campaign.blast_date = schedule;

                        const isDmsEnabled = window._utilities.isDmsEnabled()
                        if (name &&
                                schedule &&
                                validTime &&
                                hasFile &&
                                (!isDmsEnabled || (isDmsEnabled && this.campaign.campaign_code))) {
                                this.blueBtn();
                        } else {
                                this.grayBtn();
                        }
                },

                handleFileUpload() {
                        // this.campaign.file = this.$refs.file.files[0];
                        // console.log("handle file upload");
                        // var file = $('.upload-input')[0].files[0].name;
                        // $('.upload-input').prev('.upload-filename').text(file);
                        // $('.upload-input').parent('.file-btn').css("border-style","solid");
                        // $('.upload-input').next(".upload-clear").css("display","block");
                        // console.log(this.campaign.file);
                        var fileInput = document.getElementById('create-campaign-csv');
                        var filePath = fileInput.value;
                        var allowedExtensions = /(\.csv)$/i;

                        if (!allowedExtensions.exec(filePath)) {
                                alert('Please upload file having extension .csv only.');
                                fileInput.value = '';
                                return false;
                        } else {
                                //Image preview
                                if (fileInput.files && fileInput.files[0]) {
                                        this.has_file = true;
                                        console.log("Placing filename");
                                        console.log(fileInput.files[0].name);
                                        $('.upload-filename').html(fileInput.files[0].name);
                                        var reader = new FileReader();
                                        // reader.onload = function(e) {
                                        //   document.getElementById('imagePreview').innerHTML = '<img src="'+e.target.result+'"/>';
                                        // };
                                        reader.readAsDataURL(fileInput.files[0]);
                                        this.campaign.file = fileInput.files[0];
                                        // var file = $('.upload-input')[0].files[0].name;
                                        // $(('.upload-filename').text(file);
                                        $('.upload-input').parent('.file-btn').css("border-style", "solid");
                                        $('.upload-input').next(".upload-clear").css("display", "block");
                                }

                        }
                        this.checkSubmitButton();
                },

                clearUpload() {
                        console.log("clearing upload");
                        this.file = '';
                        this.$refs.file.files[0] = '';
                        $('.upload-filename').html()
                        var reader = new FileReader();
                        var fileInput = document.getElementById('create-campaign-csv');
                        $('.campaign_file').val('');
                        let variables = this.variables;
                        // var control = $(".upload-clear").prev('.upload-input');
                        // control.replaceWith( control.val('').clone( true ) );
                        $(".upload-clear")
                                .prev('.upload-input')
                                .prev('.upload-filename')
                                .text('')
                                .append("Drag and drop to upload a file. <br> in .jpg, .pdf or <a href=''>browse files</a>");

                        $(".upload-clear")
                                .prev('.upload-input')
                                .prev('.upload-filename.campaign')
                                .text('')
                                .append("<strong>Drag and drop to upload a file.</strong><br>format: " + variables + " <br><a href='#'>Browse files</a>");

                        $(".upload-clear")
                                .parent('.file-btn')
                                .css("border-style", "dashed");

                        $(".upload-clear").css("display", "none");
                        this.checkSubmitButton();
                        $(".submit-documents .upload-input").each(function () {
                                if ($(this).get(0).files.length === 0) {
                                        $(".submit-documents input.send-files").addClass("gray-btn");
                                }
                                else {
                                        $(".submit-documents input.send-files").removeClass("gray-btn");
                                        return false;
                                }
                        });
                        let campaign_file = $("#modal-campaign-create .upload-input");
                        campaign_file.val('');
                        this.checkSubmitButton();
                },

                clearUploadText() {
                        $('.campaign_file').val('');

                        $(".upload-clear")
                                .prev('.upload-input')
                                .prev('.upload-filename.campaign')
                                .text('')
                                .append("<strong>Drag and drop to upload a file.</strong><br>format: " + this.variables + " <br><a href=''>Browse files</a>");

                        $(".upload-clear")
                                .parent('.file-btn')
                                .css("border-style", "dashed");

                        $(".upload-clear").css("display", "none");

                        $(".submit-documents .upload-input").each(function () {
                                if ($(this).get(0).files.length === 0) {
                                        $(".submit-documents input.send-files").addClass("gray-btn");
                                }
                                else {
                                        $(".submit-documents input.send-files").removeClass("gray-btn");
                                        return false;
                                }
                        });
                },

                forCreateIvr() {
                        console.log(this.checkLimitIvr());
                        this.checkSubmitButtonIvr();

                        window._utilities.runIf({
                                dmsFn: () => {
                                        this.$store.dispatch('fetchIvrTreeGroups', { page: 0, per: 1000 })
                                        this.ivr_tree_group_id = this.$store.getters.getActiveIvrGroup.id || -1
                                }
                        })

                        this.ivr_name = '';
                        this.voice = '';

                        $('.select-ivr-item input:checked').prop('checked', false);
                },

                checkLimitIvr() {
                        const url = '/ivr_trees/check_limit'
                        axios.get(url
                        ).then(response => {
                                console.log(response.data.reached_limit);
                                if (response.data.reached_limit) {
                                        this.max_ivr_tree = response.data.max_ivr_tree;
                                        $('#modal-ivr-create-limit').modal('show');
                                } else {
                                        $('#modal-ivr-create').modal('show');
                                }
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => { });
                },

                forCreate() {
                        this.ivr_trees_is_loading = true;
                        const url = window._utilities.runIf({
                                dmsFn: () => {
                                        this.ivr_tree_group_id = this.$store.getters.getActiveIvrGroup.id || -1
                                        this.ivr_tree_group_name = this.$store.getters.getActiveIvrGroup.name || ''
                                        return `/ivr_tree_groups/${this.ivr_tree_group_id}.json`
                                },
                                defaultFn: () => { return '/ivr_trees.json' }
                        })

                        this.campaign.unanswered = false
                        this.campaign.busy = false
                        this.campaign.unreachable = false

                        axios.get(url
                        ).then(response => {
                                window._utilities.runIf({
                                        dmsFn: () => {
                                                const ivr_trees = response.data.data.ivr_trees
                                                ivr_trees.map((ivr_tree) => {
                                                        ivr_tree.variables = ivr_tree.dms_tree_variables
                                                        return ivr_tree
                                                })

                                                this.ivr_trees = { data: ivr_trees }
                                        },
                                        defaultFn: () => {
                                                this.ivr_trees = response.data;
                                        }
                                })

                                $('.display-time').val(" ");
                                this.for_edit = false;
                                this.has_file = false;
                                this.campaign.name = '';
                                this.campaign.campaign_code = '';
                                this.campaign.blast_date = '';
                                this.campaign.blast_time = {
                                        hh: 'hh',
                                        mm: 'mm',
                                        A: 'A'
                                };
                                this.campaign.ivr_tree_id = '';
                                this.campaign.file = '';
                                this.errors = [];
                                this.selected_ivr = '';
                                this.show_errors = false;
                                $('.upload-filename').html('Upload CSV');
                                // this.clearUpload();

                                $('#ivr_next').removeClass('blue-btn').addClass('gray-btn');
                                $('.select-ivr-item input:checked').prop('checked', false);
                                this.grayBtn();

                                $('#modal-select-ivr').modal('show');
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => {
                                this.ivr_trees_is_loading = false;
                                $(".ivr-card-item .btn-actions").click(function (e) {
                                        $(".ivr-card-item .btn-actions").not(this).siblings(".actions").css("display", "none");
                                        $(this).siblings(".actions").toggle();
                                });
                        });
                },

                getIvrTrees() {
                        this.ivr_trees_is_loading = true;
                        let url = '/ivr_trees.json';

                        axios.get(url
                        ).then(response => {
                                this.ivr_trees = response.data
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => {
                                this.ivr_trees_is_loading = false;
                                $(".ivr-card-item .btn-actions").click(function (e) {
                                        $(".ivr-card-item .btn-actions").not(this).siblings(".actions").css("display", "none");
                                        $(this).siblings(".actions").toggle();
                                });
                        });
                },

                createIvrTree() {
                        let url = '/ivr_trees';
                        let formData = new FormData();

                        formData.append('ivr_tree[name]', this.name);
                        formData.append('ivr_tree[caller_id]', this.caller_id);
                        formData.append('ivr_tree[non_globe_caller_id]', this.non_globe_caller_id);
                        formData.append('ivr_tree[sms_masking]', this.sms_masking);
                        formData.append('ivr_tree[voice]', this.voice);
                        formData.append('ivr_tree[ivr_template]', this.ivr_template);

                        window._utilities.runIf({
                                dmsFn: () => { formData.append('ivr_tree[ivr_tree_group_id]', this.ivr_tree_group_id); }
                        })

                        axios.post(url,
                                formData,
                                {
                                        headers: {
                                                'Content-Type': 'multipart/form-data'
                                        },
                                        onUploadProgress: function (progressEvent) {
                                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                                        }.bind(this)
                                }
                        ).then(response => {
                                console.log("SUCCESS!!!")
                                //  this.accounts = response.data
                                if (response.data.errors != undefined) {
                                        console.log("Create campaign with errrors")
                                        console.log(response.data.errors)
                                        this.errors = response.data.errors;
                                        this.show_errors = true;
                                } else {
                                        this.created_ivr_id = response.data.ivr_id;

                                        if (window.eventBus) { window.eventBus.$emit('ivr-tree-created') }

                                        $('#modal-ivr-create').modal('hide');
                                        $('#modal-ivr-success').modal('show');
                                        console.log(response.data);
                                }
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => this.is_loading = false);
                },

                viewAllCampaigns() {
                        const { $emit } = useNuxtApp()
                        $emit("campaign-get-campaigns")
                        this.selectedDate = ''
                },

                createCampaign() {
                        this.grayBtn()
                        let vm = this;
                        this.show_errors = false;
                        $('.retry-attemps #timepicker').val('');
                        let url = '/campaigns';
                        let formData = new FormData();
                        let blast_at = moment(vm.campaign.blast_date).format("DD-MM-YYYY") + " " + vm.campaign.blast_attempts[0].blast_at + "+08"

                        formData.append('campaign[name]', this.campaign.name);

                        window._utilities.runIf({
                                dmsFn: () => { formData.append('campaign[campaign_code]', this.campaign.campaign_code); }
                        })

                        formData.append('campaign[blast_at]', blast_at);
                        formData.append('campaign[blast_attempts][]blast_at', blast_at);
                        formData.append('campaign[blast_attempts][]status_filter[]', []);

                        if (this.campaign.unanswered == true || this.campaign.busy == true || this.campaign.unreachable == true) {
                                formData.append('campaign[blast_attempts][]blast_at', moment(vm.campaign.blast_date).format("DD-MM-YYYY") + " " + vm.campaign.blast_attempts[1].blast_at + "+08");
                                $.each(this.campaign.blast_attempts, function (i, val) {
                                        if (i != 0) {
                                                $.each(val.status_filter, function (index, value) {
                                                        formData.append('campaign[blast_attempts][]status_filter[]', vm.campaign.blast_attempts[i].status_filter[index]);
                                                })
                                        }
                                })
                        }

                        formData.append('campaign[ivr_tree_id]', this.selected_ivr);
                        if (this.campaign.file != undefined) {
                                formData.append('campaign[file]', this.campaign.file);
                        }

                        const options = {
                                headers: {
                                        'Content-Type': 'multipart/form-data'
                                },
                                onUploadProgress: function (progressEvent) {
                                        this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                                }.bind(this)
                        }

                        const handleResponse = (response) => {
                                if (response.data.errors != undefined) {
                                        if (response.data.errors.indexOf('Blast at should be from') !== -1) {
                                                this.errors = ["Time should be from 8:00 AM to 6:00 PM"];
                                        } else {
                                                this.errors = response.data.errors
                                        }
                                        this.show_errors = true;
                                } else {
                                        this.show_errors = false;
                                        const { $emit } = useNuxtApp()
                                        $emit("campaign-get-campaigns", true)
                                        Object.assign(this.campaign, response.data)
                                        this.show_campaign_list = false;
                                        this.show_campaign = true;

                                        setTimeout(function () {
                                                $('#modal-campaign-create').modal('hide');
                                                $('#modal-campaign-success').modal('show');
                                        }, 100);
                                }
                        }

                        const handleError = (error) => {
                                if (error && error.response && error.response.data && error.response.data.errors) {
                                        this.errors = error.response.data.errors
                                        this.show_errors = true;
                                }
                        }

                        const handleCleanup = () => {
                                this.blueBtn()
                                this.is_loading = false
                                this.forCaleandar();
                        }

                        axios.post(url, formData, options)
                                .then(handleResponse)
                                .catch(handleError)
                                .finally(handleCleanup);

                        $('#modal-campaign-success').on('hidden.bs.modal', function () {
                                vm.viewCampaign(vm.campaign.id)
                        })
                },

                editCampaign(cId) {
                        this.errors = [];
                        this.show_errors = false;
                        let vm = this
                        let url = '/campaigns/' + cId + '/edit.json';
                        this.for_edit = true;
                        this.checkSubmitButton();

                        this.campaign.unanswered = false
                        this.campaign.busy = false
                        this.campaign.unreachable = false

                        axios.get(url
                        ).then(response => {
                                console.log("SUCCESS!!!")

                                let data_campaign = response.data;
                                this.campaign.id = data_campaign.id;
                                this.campaign.name = data_campaign.name;
                                this.campaign.blast_date = data_campaign.blast_date;
                                this.campaign.campaign_record_count = data_campaign.campaign_record_count;
                                this.campaign.ivr_status = data_campaign.ivr_status;
                                this.campaign.filename = data_campaign.filename;
                                this.campaign.blast_time = data_campaign.blast_attempts[0].blast_time;
                                this.campaign.blast_attempts[0].blast_at = data_campaign.blast_attempts[0].blast_time;
                                this.campaign.blast_attempts[0].status_filter = [];
                                console.log(data_campaign.blast_attempts.length === 2);

                                if (data_campaign.blast_attempts.length === 2) {
                                        this.campaign.blast_attempts[1].blast_at = data_campaign.blast_attempts[1].blast_time;
                                        this.campaign.blast_attempts[1].status_filter = data_campaign.blast_attempts[1].status_filter;
                                }

                                let b = data_campaign.blast_attempts[0].blast_time.split('');

                                this.campaign.blast_time = {
                                        hh: b[0] + b[1],
                                        mm: b[3] + b[4],
                                        A: b[6] + b[7]
                                }

                                this.campaign.blast_attempts[0].blast_at = {
                                        hh: b[0] + b[1],
                                        mm: b[3] + b[4],
                                        A: b[6] + b[7]
                                };

                                $('.retry-attemps #timepicker').val('');

                                if (data_campaign.blast_attempts.length === 2) {
                                        let c = data_campaign.blast_attempts[1].blast_time.split('');

                                        this.campaign.blast_attempts[1].blast_at = {
                                                hh: c[0] + c[1],
                                                mm: c[3] + c[4],
                                                A: c[6] + c[7]
                                        };


                                        if (this.campaign.blast_attempts[1].status_filter.indexOf('3') !== -1) {
                                                this.campaign.unanswered = true
                                                this.campaign.isTimeEnable = true
                                        }

                                        if (this.campaign.blast_attempts[1].status_filter.indexOf('4') !== -1) {
                                                this.campaign.busy = true
                                                this.campaign.isTimeEnable = true
                                        }

                                        if (this.campaign.blast_attempts[1].status_filter.indexOf('6') !== -1) {
                                                this.campaign.unreachable = true
                                                this.campaign.isTimeEnable = true
                                        }
                                }

                                this.campaign.ivr_tree_id = data_campaign.ivr_tree_id;
                                this.selected_ivr = data_campaign.ivr_tree_id;
                                this.campaign.ivr_tree_name = data_campaign.ivr_tree_name;
                                $('#modal-select-ivr').modal('show');

                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => this.is_loading = false);


                },

                updateCampaign(cId) {
                        let url = '/campaigns/' + cId;
                        let formData = new FormData();
                        this.show_errors = false;
                        let vm = this;
                        let blast_at = moment(vm.campaign.blast_date).format("DD-MM-YYYY") + " " + vm.campaign.blast_attempts[0].blast_at + "+08"

                        formData.append('campaign[name]', this.campaign.name);
                        formData.append('campaign[blast_at]', blast_at);

                        formData.append('campaign[blast_attempts][]blast_at', blast_at);

                        formData.append('campaign[blast_attempts][]status_filter[]', []);
                        if (vm.campaign.blast_attempts[1].status_filter.length !== 0) {
                                formData.append('campaign[blast_attempts][]blast_at', moment(vm.campaign.blast_date).format("DD-MM-YYYY") + " " + vm.campaign.blast_attempts[1].blast_at + "+08");

                                $.each(this.campaign.blast_attempts, function (i, val) {
                                        if (i != 0) {
                                                $.each(val.status_filter, function (index, value) {
                                                        formData.append('campaign[blast_attempts][]status_filter[]', vm.campaign.blast_attempts[i].status_filter[index]);
                                                })
                                        }
                                })
                        }

                        formData.append('campaign[ivr_tree_id]', this.selected_ivr);
                        if (this.campaign.file != undefined) {
                                formData.append('campaign[file]', this.campaign.file);
                        }

                        axios.put(url,
                                formData,
                                {
                                        headers: {
                                                'Content-Type': 'multipart/form-data'
                                        },
                                        onUploadProgress: function (progressEvent) {
                                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                                        }.bind(this)
                                }
                        ).then(response => {
                                if (response.data.errors != undefined) {

                                        if (response.data.errors.indexOf('Blast at should be from') !== -1) {
                                                this.errors = ["Time should be from 8:00 AM to 6:00 PM"];
                                        } else {
                                                this.errors = response.data.errors
                                        }
                                        this.show_errors = true;
                                } else {
                                        this.show_errors = false;
                                        const { $emit } = useNuxtApp()
                                        $emit("campaign-get-campaigns", true)
                                        this.campaign = response.data;
                                        this.show_campaign_list = false;
                                        this.show_campaign = true;
                                        setTimeout(function () {
                                                $emit("campaign-show-campaign", cId)
                                                $('#modal-campaign-create').modal('hide');
                                                $('#modal-campaign-update').modal('show');
                                        }, 100);
                                }
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                                console.log(error.response);
                        }).finally(() => {
                                this.blueBtn()
                                this.is_loading = false
                                this.forCaleandar();
                        });

                        $('#modal-campaign-update').on('hidden.bs.modal', function () {
                                vm.viewCampaign(vm.campaign.id)
                        })
                },

                editIvr(id) {
                        this.ivr_tree_is_loading = true;
                        let url = '/ivr_trees/' + id + '/edit';

                        axios.get(url
                        ).then(response => {
                                console.log("IVR TREE SUCCESSddd!!!");
                                this.ivr_tree = response.data;
                                this.name = this.ivr_tree.name;
                                this.caller_id = this.ivr_tree.caller_id;
                                this.sms_masking = this.ivr_tree.sms_masking;
                                this.voice = this.ivr_tree.voice;
                                $('#modal-ivr-setting').modal('show');
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => this.ivr_tree_is_loading = false);
                },

                ivrVariables(variables) {
                        if (!variables) {
                                return
                        }

                        let output = '';
                        var variablesSplit = variables.split(",");

                        $.each(variablesSplit, function (index, string) {
                                let vars = [];
                                let words = string.split(' ');

                                for (let i = 0; i < words.length; i++) {

                                        let lowerWord = words[i].toLowerCase();
                                        lowerWord = lowerWord.trim();
                                        const capitalizedWord = lowerWord.slice(0, 1) + lowerWord.slice(1);

                                        if (i != words.length - 1) {
                                                output += capitalizedWord + ' ';
                                        } else {
                                                if (index != variablesSplit.length - 1) {
                                                        output += capitalizedWord + ', ';
                                                } else {
                                                        output += capitalizedWord;
                                                }
                                        }
                                }//for
                                // NOTE(aes): strings are immutable! this doesn't do anything
                                // output[output.length - 1] = '';
                        });
                        return output;
                },

                getVoice(val) {
                        switch (val) {
                                case 1:
                                        return "Male"
                                default:
                                        return "Female"
                        }
                },

                activateButton(id, name) {
                        this.selected_ivr = id;
                        this.ivr_name = name;
                        $("#ivr_next")
                                .addClass("blue-btn")
                                .removeClass("gray-btn")
                                .css({ "pointer-events": "auto" });
                },

                retrieveVariableFormat() {
                        let sameIvrId = false;
                        let url = '/ivr_trees/' + this.selected_ivr + '/fetch_variables';
                        sameIvrId = (this.selected_ivr == this.campaign.ivr_tree_id);

                        $('#variable-tags ul').html(' ');

                        axios.get(url
                        ).then(response => {
                                this.variables = response.data.variables;
                                this.ivr_name = response.data.name

                                window._utilities.runIf({
                                        dmsFn: () => {
                                                this.dms_variables = response.data.dms_variables;
                                                $.each(this.dms_variables, function (i, v) {
                                                        $('#variable-tags ul').append('<li>' + v + '</li>');
                                                })
                                        },
                                        defaultFn: () => {
                                                $.each(this.variables, function (i, v) {
                                                        $('#variable-tags ul').append('<li>' + v + '</li>');
                                                })
                                        }
                                })

                                $('#modal-select-ivr').modal('hide');
                                $('#modal-campaign-create').modal('show');

                                if (this.for_edit) {
                                        if (sameIvrId) {
                                                this.has_file = true;
                                                $('.upload-filename').html(this.campaign.filename);
                                                $('.upload-input').parent('.file-btn').css("border-style", "solid");
                                                $('.upload-input').next(".upload-clear").css("display", "block");
                                        } else {
                                                this.has_file = false;
                                                $('.upload-filename').html('Upload CSV');
                                                var fileInput = document.getElementById('create-campaign-csv')
                                                fileInput.files = {};
                                        }
                                } else {
                                        this.campaign.name = '';
                                        this.campaign.blast_date = ''
                                        this.clearUpload();
                                }
                        }).catch(error => {
                                console.log(error);
                        }).finally(() => {
                                this.checkSubmitButton();
                        });

                },

                defaultIvrValues() {
                        let url = '/ivr_trees/default_ivr_values.json';
                        axios.get(url
                        ).then(response => {
                                this.caller_id = response.data.caller_id;
                                this.sms_masking = response.data.sms_masking;
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        })
                },

                blastTime(date) {
                        console.log("====== blastTime from creators.js")
                        var date = new Date(date);
                        date = date.toLocaleDateString('en', { weekday: 'short', month: 'short', day: "numeric", year: 'numeric' })

                        return " - " + date.toString("MMMM dd, yyyy");
                },

                statusTd(status) {
                        switch (status) {
                                case (1):
                                        return "status-done"
                                case (2):
                                        return "status-failed"
                                default:
                                        return "status-queue"
                        }
                },

                voiceGender(value) {
                        switch (value) {
                                case 1:
                                        return 'Male'
                                        break;
                                default:
                                        return 'Female'
                        }
                },

                yourTimeValue() {
                        // this.campaign.blast_time

                        return {
                                hh: "11",
                                mm: "05",
                                A: 'PM'
                        }
                },

                forCaleandar(data) {
                        $('#caleandar').empty();
                        // Query to get calendar campaigns
                        const url = '/dashboards/for_caleandar';

                        let vm = this;

                        if (data == undefined) {
                                var settings = new Date();
                        } else {
                                var settings = data;
                        }

                        axios.get(url,
                                {
                                        params: {
                                                date_caleandar: settings
                                        }
                                }
                        ).then(response => {
                                this.caleandar_data = response.data
                                var events = [];

                                var item_date = '';
                                var item_title = '';
                                var item_status = '';
                                var item_ivr = '';
                                var item_csv = '';
                                var item_volume = '';
                                var item_callerid = '';
                                var item_smsmasking = '';
                                var item_voice = '';
                                var item_variables = '';
                                var hover_item = '';
                                var campaign_date = '';
                                var campaign_status = '';
                                var campaign_title = '';
                                var campaign_time = '';
                                var hover_calendar = '';
                                var c_id = '';

                                $.each(this.caleandar_data.campaigns, function (k, v) {
                                        hover_item = '';
                                        $.each(v.data, function (i, value) {
                                                item_title = value.name;
                                                item_status = value.status;
                                                item_callerid = value.ivr_caller_id;
                                                item_smsmasking = value.ivr_sms_masking;
                                                item_variables = value.variables
                                                campaign_date = value.blast_date;
                                                c_id = value.id;

                                                status = '';
                                                switch (item_status) {
                                                        case 1:
                                                                status = "ongoing"
                                                                break;
                                                        case 2:
                                                                status = "done";
                                                                break;
                                                        default:
                                                                status = "queue"
                                                }
                                                let voice = '';
                                                switch (item_voice) {
                                                        case 1:
                                                                voice = 'Male';
                                                                break;
                                                        default:
                                                                voice = 'Female';
                                                }

                                                hover_item += '<div class="hover-calendar-item-details-wrap hide" id="campaign' + c_id + '">\
                                 <div class="hover-calendar-item-details">\
                                   <div class="hover-date" onclick="creators.showHoverCalendar(this)">\
                                     <img src="~/assets/images/client/ic_chevright.svg" class="back-btn">' + k + '\
                                   </div><br>';
                                                hover_item += '<div class="hover-title">\
                                  <h1>' + item_title + '</h1>\
                                  <div class="status done '+ status + '">' + status + '</div>\
                                </div>\
                                <div class="hover-details"><br>\
                                    <div><div id="ic-callerid" class="ic"></div><strong>Caller ID: </strong>' + item_callerid + '</div><br>\
                                    <div><div id="ic-smsmasking" class="ic"></div><strong>SMS Masking: </strong>' + item_smsmasking + '</div><br>\
                                    <div><div id="ic-voice" class="ic"></div><strong>Voice: </strong>' + voice + '</div><br>\
                                    <div><div id="ic-variables" class="ic"></div><strong>Variables: </strong><div class="item-variables">' + item_variables + '</div></div><br>\
                                </div>';
                                                hover_item += '</div></div>';
                                        });

                                        var hover_calendar = '<div class="hover-calendar-wrap">\
                                      <div class="hover-calendar">\
                                        <div class="hover-calendar-date">\
                                          <img src="~/assets/images/client/ic_calendar_white.svg">'+ campaign_date + '\
                                        </div>';
                                        // // Campaign Date
                                        $.each(v.data, function (i, value) {
                                                campaign_status = value.status;
                                                status = '';
                                                switch (campaign_status) {
                                                        case 1:
                                                                status = 'queue';
                                                                break;
                                                        case 2:
                                                                status = 'done';
                                                                break;
                                                        case 3:
                                                                status = 'failed';
                                                                break;
                                                        default:
                                                                status = 'queue';
                                                }

                                                campaign_title = value.name;
                                                campaign_time = value.blast_time;
                                                c_id = value.id;

                                                hover_calendar += '<div class="hover-calendar-item">\
                                      <div class="left"><div class="'+ status + '">' + status + '</div></div>\
                                      <div class="center">\
                                        '+ campaign_title + '\
                                        <br>\
                                        <span class="time">'+ campaign_time + '</span>\
                                      </div>\
                                      <div class="right">\
                                        <span class="open-campaign-details" onclick="creators.showCaleandarInfo(this)" data-cid = '+ c_id + '>\
                                          <img src="~/assets/images/client/ic_chevleft.svg">\
                                        </span>\
                                      </div>\
                                    </div>';
                                        });
                                        hover_calendar += '</div>' + hover_item + '</div>';
                                        //
                                        //
                                        hover_calendar = '<div style="display:none" id="calendar-date">' + campaign_date + '</div>';
                                        const bDate = v.blast_date.split('-');
                                        //
                                        events.push({ 'Date': new Date(bDate[0], bDate[1] - 1, bDate[2]), 'Title': hover_calendar });

                                });

                                $("#caleandar").on('click', '.cld-number.eventday', function (e) {

                                        vm.selectedDate = $(e.target).find('#calendar-date').text();
                                        vm.filterSelectedDate = moment(vm.selectedDate).format('MMMM Do YYYY')
                                        campaign.show_campaign_list = true;
                                        campaign.show_campaign = false;

                                        let url = 'campaigns/list.json?date=' + vm.selectedDate;

                                        axios.get(url
                                        ).then(response => {
                                                campaign.campaigns = response.data;

                                                campaign.show_campaign = false;
                                                campaign.show_campaign_list = true;
                                                campaign.is_loading = false;

                                        }).catch(error => {
                                                console.log("FAIL!!!")
                                                console.log(error);
                                        }).finally(() => {
                                                campaign.show_search_list = true;
                                                campaign.show_campaign_list = true;
                                        });
                                })



                                var element = document.getElementById('caleandar');
                                $('#caleandar').empty();
                                caleandar(element, events, settings);
                                $('.cld-nav').hide();



                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => {


                                $('body .cld-nav').show();

                                let count = 1;

                                $('#caleandar .cld-rwd.cld-nav').on('click', function (e) {

                                        currentDate.setMonth(currentDate.getMonth() - count);
                                        this.forCaleandar(currentDate);
                                        vm.selectedDate = moment(currentDate).format('YYYY-MM-DD')
                                        vm.filterSelectedDate = moment(vm.selectedDate).format('MMMM-YYYY')

                                        campaign.show_campaign_list = true;
                                        campaign.show_campaign = false;

                                        let url = 'campaigns/list.json?month_date=' + vm.selectedDate;

                                        axios.get(url
                                        ).then(response => {

                                                campaign.campaigns = response.data;

                                                campaign.show_campaign = false;
                                                campaign.show_campaign_list = true;
                                                campaign.is_loading = false;

                                        }).catch(error => {
                                                console.log("FAIL!!!")
                                                console.log(error);
                                        }).finally(() => {
                                                campaign.show_search_list = true;
                                                campaign.show_campaign_list = true;
                                        });

                                        count++;
                                })

                                $('#caleandar .cld-fwd.cld-nav').on('click', function (e) {
                                        currentDate.setMonth(currentDate.getMonth() + 1);
                                        this.forCaleandar(currentDate);
                                        vm.selectedDate = moment(currentDate).format('YYYY-MM-DD')
                                        vm.filterSelectedDate = moment(vm.selectedDate).format('MMMM-YYYY')

                                        campaign.show_campaign_list = true;
                                        campaign.show_campaign = false;

                                        let url = 'campaigns/list.json?month_date=' + vm.selectedDate;

                                        axios.get(url
                                        ).then(response => {
                                                campaign.campaigns = response.data;

                                                campaign.show_campaign = false;
                                                campaign.show_campaign_list = true;
                                                campaign.is_loading = false;

                                        }).catch(error => {
                                                console.log("FAIL!!!")
                                                console.log(error);
                                        }).finally(() => {
                                                campaign.show_search_list = true;
                                                campaign.show_campaign_list = true;
                                        });
                                })

                        });
                },

                showCaleandarInfo(e) {
                        let cid = $(e).data('cid');

                        $('.hover-calendar-item-details-wrap#campaign' + cid).removeClass("hide");
                        $(e).closest($('.hover-calendar')).addClass("hide");
                },

                showHoverCalendar(e) {
                        $(e).closest($('.hover-calendar-item-details-wrap')).addClass('hide');
                        $('.hover-calendar').removeClass('hide');
                },

                viewIvrTree() {
                        useRouter().push(`/ivr_trees/${this.created_ivr_id}/editor`)
                },

                viewCampaign(campaign_id) {
                        let origin = $(location).attr('origin');

                        // TODO(aes): is this relevant?
                        // campaign.show_campaign = true;

                        // TODO(aes): this should be router push
                        window.location.href = origin + '/campaigns/' + campaign_id;
                },

                showCampaign(cId) {

                        campaign.show_campaign_list = false;
                        campaign.show_campaign = true;

                        let url = '/campaigns/' + cId + '.json';

                        axios.get(url
                        ).then(response => {
                                campaign.campaign = response.data;
                                campaign.campaign.name = response.data.name;
                                campaign.status = campaign.campaign.status;

                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => {
                                campaign.show_campaign = true;
                        });
                },

                customFormatter(date) {
                        return moment(date).format('MMMM Do YYYY');
                },

                grayBtn() {
                        $("#campaign_form_button").removeClass("blue-btn").addClass("gray-btn")
                },

                blueBtn() {
                        $("#campaign_form_button").removeClass("gray-btn").addClass("blue-btn")
                },

                // TODO: Remove DRY up creators.js.erb, ivr_page.js
                approvalClass(ivr) {
                        return {
                                'bg-warning': ivr.approval_status === 'For Approval',
                                'bg-success': ivr.approval_status === 'Approved',
                        }
                },

                ivrTreeDuplicatedListener(newIvrTreeId) {
                        this.created_ivr_id = newIvrTreeId
                },

                attachEventListeners() {
                        const vue = this
                        const resetError = (_) => {
                                vue.errors = []
                                vue.show_errors = false
                        }
                        $('#modal-campaign-create').on('hidden.bs.modal', resetError)
                        $('#modal-ivr-create').on('hidden.bs.modal', resetError)

                        if (window.eventBus) {
                                window.eventBus.$on('ivr-tree-duplicated', this.ivrTreeDuplicatedListener)
                        }
                },
                detachEventListeners() {
                        if (window.eventBus) {
                                window.eventBus.$off('ivr-tree-duplicated', this.ivrTreeDuplicatedListener)
                        }
                },
                onCampaignSubmit() {
                        if (this.for_edit) {
                                this.updateCampaign(this.campaign.id)
                        } else {
                                this.createCampaign()
                        }
                },
                pushCreatorsData() {
                        const { $emit } = useNuxtApp()
                        $emit("push:creators_data", {
                                selectedDate: this.selectedDate,
                                filterSelectedDate: this.filterSelectedDate,
                        })
                },
                forCreateIvrWithOptions() {
                        this.forCreateIvr()

                        const { $api } = useNuxtApp()
                        $api.IvrTree.form_options({
                                onError: () => {
                                        // TODO(aes): handle error
                                },
                                onSuccess: (data) => {
                                        this.caller_id_select_options = data.caller_id_select_options
                                        this.caller_id = data.default_caller_id

                                        this.non_globe_caller_id_select_options = data.non_globe_caller_id_select_options
                                        this.non_globe_caller_id = this.caller_id

                                        this.sms_masking_for_listing = data.sms_masking_for_listing
                                        this.voices = [["-- Select Voice --", ''], ...data.voices]
                                },
                        })
                },
        },
        computed: {
                nextIvrClickable: function () {
                        // console.log(this.campaign.ivr_status);
                        // console.log(this.campaign.ivr_status == 2);
                        return this.campaign.ivr_status == 2 ? 'gray-btn' : 'blue-btn'
                },
                ivrTreeGroups() {
                        return this.$store.getters.getIvrTreeGroups
                },
        },

        watch: {
                'campaign.campaign_code': function handleCampaignCodeChange(_) {
                        this.checkSubmitButton()
                },

                // NOTE(aes): see note about `created()` for these pushers
                selectedDate(value) {
                        const { $emit } = useNuxtApp()
                        $emit("push:creators_data", { selectedDate: value })
                },
                filterSelectedDate(value) {
                        const { $emit } = useNuxtApp()
                        $emit("push:creators_data", { filterSelectedDate: value })
                },
        },

        // NOTE(aes): since this component is instantiated in layouts/default.vue,
        //            there is no way to directly pass data from this component
        //            to a component inside the page
        //
        //            for this reason, a push-pull mechanism is used, whereby
        //            the component that requires some data will emit a "pull",
        //            and then this component will provide that data by emitting a "push"
        //
        //            the pull listener is instantiated on the `beforeMount()` hook,
        //            while the push should occur in the `mounted()` hook
        //            to avoid race conditions
        beforeMount() {
                const { $on } = useNuxtApp()
                $on("pull:creators_data", this.pushCreatorsData)
        },

        mounted() {
                if (App.userId) {
                        this.getIvrTrees();
                        this.defaultIvrValues();
                }
                this.attachEventListeners()

                initCaleandar()

                const { $on } = useNuxtApp()
                $on("creators-for-create", this.forCreate)
                $on("creators-for-create-ivr", this.forCreateIvrWithOptions)
                $on("creators_view_all_campaigns", this.viewAllCampaigns)
                $on("creators-edit-campaign", this.editCampaign)
        },

        beforeUnmount() {
                this.detachEventListeners()
                const { $off } = useNuxtApp()
                $off("pull:creators_data", this.pushCreatorsData)
                $off("creators-for-create", this.forCreate)
                $off("creators-for-create-ivr", this.forCreateIvrWithOptions)
                $off("creators_view_all_campaigns", this.viewAllCampaigns)
                $off("creators-edit-campaign", this.editCampaign)
        },
        // TODO(aes): this should be auto loaded
        // components: {
        //         vuejsDatepicker
        // },
}

// NOTE(aes): window reference is needed by some html strings in this file
// TODO(aes): does this even work? how to reference vue instance in vue3?
window.creators = creators

export default creators
</script>
