import { default as editnje6XZuNUJMeta } from "/codebuild/output/src457679548/src/pages/admin/account_plans/[account_plan_id]/edit.vue?macro=true";
import { default as indexRDkGzqBJAdMeta } from "/codebuild/output/src457679548/src/pages/admin/account_plans/[account_plan_id]/index.vue?macro=true";
import { default as _91account_plan_id_93jRT8l39f2ZMeta } from "/codebuild/output/src457679548/src/pages/admin/account_plans/[account_plan_id].vue?macro=true";
import { default as editLGh7s6sI9yMeta } from "/codebuild/output/src457679548/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexuEVt5UlUv2Meta } from "/codebuild/output/src457679548/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93Vx8lgfkvtfMeta } from "/codebuild/output/src457679548/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexJTQasTlZmwMeta } from "/codebuild/output/src457679548/src/pages/admin/account_requested_plans/index.vue?macro=true";
import { default as newT8sRV5dwp4Meta } from "/codebuild/output/src457679548/src/pages/admin/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plansBicvpjzzbYMeta } from "/codebuild/output/src457679548/src/pages/admin/account_requested_plans.vue?macro=true";
import { default as editKVJy8u8SNAMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indextFG82U0q7NMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93Sp3AGKXCG9Meta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as index5TLyTD2wntMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue?macro=true";
import { default as newV58oDYmfgaMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plansINPTWwhL5kMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans.vue?macro=true";
import { default as editSMiD7VEnWlMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexwRvbeHPjOiMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_939CtrsPHM09Meta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexVyy3yM2rgmMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids/index.vue?macro=true";
import { default as newlyqQpHxqfDMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids/new.vue?macro=true";
import { default as caller_idsIJxAwfWHKOMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids.vue?macro=true";
import { default as edity097BHc9fyMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/edit.vue?macro=true";
import { default as indexoUqfICqz5iMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/index.vue?macro=true";
import { default as editJgobFdcL69Meta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexgoYT0OmJoHMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93EmI6TVi5DTMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexYWkP7LqrdzMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue?macro=true";
import { default as newiGFhBWY9agMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue?macro=true";
import { default as sms_maskings8GT1MAuLxLMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings.vue?macro=true";
import { default as _91account_id_93WWmIhaeNJZMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/[account_id].vue?macro=true";
import { default as index0vjpC6oSxLMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/index.vue?macro=true";
import { default as wizard_plan_selectionegwDWOfHRiMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/wizard_plan_selection.vue?macro=true";
import { default as wizardB8FcY8TfunMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts/wizard.vue?macro=true";
import { default as accountsCl5FYHbJpuMeta } from "/codebuild/output/src457679548/src/pages/admin/accounts.vue?macro=true";
import { default as editYgmvCYFUUsMeta } from "/codebuild/output/src457679548/src/pages/admin/admin_users/[admin_user_id]/edit.vue?macro=true";
import { default as indexyDsW6OQP8cMeta } from "/codebuild/output/src457679548/src/pages/admin/admin_users/[admin_user_id]/index.vue?macro=true";
import { default as _91admin_user_id_93jDvyE6XV6YMeta } from "/codebuild/output/src457679548/src/pages/admin/admin_users/[admin_user_id].vue?macro=true";
import { default as indexknl9EfMm2VMeta } from "/codebuild/output/src457679548/src/pages/admin/admin_users/index.vue?macro=true";
import { default as newIaKHdcZCAsMeta } from "/codebuild/output/src457679548/src/pages/admin/admin_users/new.vue?macro=true";
import { default as admin_users7aUZ1PVnNyMeta } from "/codebuild/output/src457679548/src/pages/admin/admin_users.vue?macro=true";
import { default as indexwJkJXcIpuDMeta } from "/codebuild/output/src457679548/src/pages/admin/audit_trails/index.vue?macro=true";
import { default as audit_trailsJ8zT6smBSKMeta } from "/codebuild/output/src457679548/src/pages/admin/audit_trails.vue?macro=true";
import { default as editOOioiOIa3NMeta } from "/codebuild/output/src457679548/src/pages/admin/billings/[billing_id]/edit.vue?macro=true";
import { default as index7yoWg0daiaMeta } from "/codebuild/output/src457679548/src/pages/admin/billings/[billing_id]/index.vue?macro=true";
import { default as _91billing_id_93Y0bYnFp7DHMeta } from "/codebuild/output/src457679548/src/pages/admin/billings/[billing_id].vue?macro=true";
import { default as index9An1HxNb6BMeta } from "/codebuild/output/src457679548/src/pages/admin/billings/index.vue?macro=true";
import { default as newljNMg2m5eoMeta } from "/codebuild/output/src457679548/src/pages/admin/billings/new.vue?macro=true";
import { default as billingsUbhEDeoPxtMeta } from "/codebuild/output/src457679548/src/pages/admin/billings.vue?macro=true";
import { default as edituhT8foAa6xMeta } from "/codebuild/output/src457679548/src/pages/admin/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexjpNJUZGBo2Meta } from "/codebuild/output/src457679548/src/pages/admin/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93gqBBYA4A1lMeta } from "/codebuild/output/src457679548/src/pages/admin/caller_ids/[caller_id_id].vue?macro=true";
import { default as index6qztIkBO3BMeta } from "/codebuild/output/src457679548/src/pages/admin/caller_ids/index.vue?macro=true";
import { default as newYP4u35XAbEMeta } from "/codebuild/output/src457679548/src/pages/admin/caller_ids/new.vue?macro=true";
import { default as caller_idsYEDpZveR90Meta } from "/codebuild/output/src457679548/src/pages/admin/caller_ids.vue?macro=true";
import { default as edit2LzzE4awtwMeta } from "/codebuild/output/src457679548/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue?macro=true";
import { default as indexGrwmTvYYYlMeta } from "/codebuild/output/src457679548/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue?macro=true";
import { default as _91campaign_setting_id_93wWslQPd5WXMeta } from "/codebuild/output/src457679548/src/pages/admin/campaign_settings/[campaign_setting_id].vue?macro=true";
import { default as editOCsnwqgA4OMeta } from "/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/edit.vue?macro=true";
import { default as indexzGLPbK56XCMeta } from "/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/index.vue?macro=true";
import { default as editvHhQYYrJ8cMeta } from "/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue?macro=true";
import { default as indexTc2b3Lu3GJMeta } from "/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue?macro=true";
import { default as _91outbound_call_transaction_id_938gGxxsEeR5Meta } from "/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue?macro=true";
import { default as index4k9uujfPzQMeta } from "/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue?macro=true";
import { default as new4hwP6ze6i1Meta } from "/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue?macro=true";
import { default as outbound_call_transactionsqqvpQvawJwMeta } from "/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue?macro=true";
import { default as _91campaign_id_93GveOb2rJjEMeta } from "/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id].vue?macro=true";
import { default as indexafbGFwE2gAMeta } from "/codebuild/output/src457679548/src/pages/admin/campaigns/index.vue?macro=true";
import { default as newKk5fbZyaonMeta } from "/codebuild/output/src457679548/src/pages/admin/campaigns/new.vue?macro=true";
import { default as campaignsTZpDP9pEJvMeta } from "/codebuild/output/src457679548/src/pages/admin/campaigns.vue?macro=true";
import { default as indexVyEy8iGibfMeta } from "/codebuild/output/src457679548/src/pages/admin/custom_emails/[custom_email_id]/index.vue?macro=true";
import { default as _91custom_email_id_93wnIdfHKbFWMeta } from "/codebuild/output/src457679548/src/pages/admin/custom_emails/[custom_email_id].vue?macro=true";
import { default as indexvlsJMU8744Meta } from "/codebuild/output/src457679548/src/pages/admin/custom_emails/index.vue?macro=true";
import { default as newZoClRg2yymMeta } from "/codebuild/output/src457679548/src/pages/admin/custom_emails/new.vue?macro=true";
import { default as custom_emailsJhd0wiKPWmMeta } from "/codebuild/output/src457679548/src/pages/admin/custom_emails.vue?macro=true";
import { default as indexdsNwl8OaWdMeta } from "/codebuild/output/src457679548/src/pages/admin/dashboard/index.vue?macro=true";
import { default as dashboardBJjjGVrMVNMeta } from "/codebuild/output/src457679548/src/pages/admin/dashboard.vue?macro=true";
import { default as indexROeIQuqRN1Meta } from "/codebuild/output/src457679548/src/pages/admin/inquiries/[inquiry_id]/index.vue?macro=true";
import { default as _91inquiry_id_93bo5gCCUPXgMeta } from "/codebuild/output/src457679548/src/pages/admin/inquiries/[inquiry_id].vue?macro=true";
import { default as index1WavoXruukMeta } from "/codebuild/output/src457679548/src/pages/admin/inquiries/index.vue?macro=true";
import { default as newJixyayeRWEMeta } from "/codebuild/output/src457679548/src/pages/admin/inquiries/new.vue?macro=true";
import { default as inquiriestHjQpjBEvwMeta } from "/codebuild/output/src457679548/src/pages/admin/inquiries.vue?macro=true";
import { default as indexa9UR9hbg2YMeta } from "/codebuild/output/src457679548/src/pages/admin/login/index.vue?macro=true";
import { default as login3MDQy1mkOoMeta } from "/codebuild/output/src457679548/src/pages/admin/login.vue?macro=true";
import { default as newIiF1AIl57tMeta } from "/codebuild/output/src457679548/src/pages/admin/password/new.vue?macro=true";
import { default as indexUMk1JJA8OMMeta } from "/codebuild/output/src457679548/src/pages/admin/reports/index.vue?macro=true";
import { default as reportsFnapGCm3lhMeta } from "/codebuild/output/src457679548/src/pages/admin/reports.vue?macro=true";
import { default as editzNuPUsHL7LMeta } from "/codebuild/output/src457679548/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexrbAOYsSignMeta } from "/codebuild/output/src457679548/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93NUM3pN6WW8Meta } from "/codebuild/output/src457679548/src/pages/admin/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexWA4uEKQhzpMeta } from "/codebuild/output/src457679548/src/pages/admin/sms_maskings/index.vue?macro=true";
import { default as newDaag2VN2pqMeta } from "/codebuild/output/src457679548/src/pages/admin/sms_maskings/new.vue?macro=true";
import { default as sms_maskings0QGAs4u8g7Meta } from "/codebuild/output/src457679548/src/pages/admin/sms_maskings.vue?macro=true";
import { default as editOUt1oftNVYMeta } from "/codebuild/output/src457679548/src/pages/admin/users/[user_id]/edit.vue?macro=true";
import { default as indexRPb1JirnEdMeta } from "/codebuild/output/src457679548/src/pages/admin/users/[user_id]/index.vue?macro=true";
import { default as _91user_id_933ITIUmxuIJMeta } from "/codebuild/output/src457679548/src/pages/admin/users/[user_id].vue?macro=true";
import { default as indexpbPVAKcKuKMeta } from "/codebuild/output/src457679548/src/pages/admin/users/index.vue?macro=true";
import { default as newSIrvJNlmSMMeta } from "/codebuild/output/src457679548/src/pages/admin/users/new.vue?macro=true";
import { default as usersfi9Mdvx86EMeta } from "/codebuild/output/src457679548/src/pages/admin/users.vue?macro=true";
import { default as editTl4z41I6t3Meta } from "/codebuild/output/src457679548/src/pages/admin/wallets/[wallet_id]/edit.vue?macro=true";
import { default as indexH5AJYWtJCgMeta } from "/codebuild/output/src457679548/src/pages/admin/wallets/[wallet_id]/index.vue?macro=true";
import { default as _91wallet_id_93oHNjl8XQTRMeta } from "/codebuild/output/src457679548/src/pages/admin/wallets/[wallet_id].vue?macro=true";
import { default as admin8LZmwE0pW5Meta } from "/codebuild/output/src457679548/src/pages/admin.vue?macro=true";
import { default as indextbKQ8ygAKjMeta } from "/codebuild/output/src457679548/src/pages/audit_logs/index.vue?macro=true";
import { default as index5me68PGBLuMeta } from "/codebuild/output/src457679548/src/pages/billings/index.vue?macro=true";
import { default as indexDC3pbQoeAzMeta } from "/codebuild/output/src457679548/src/pages/call_out/reports/index.vue?macro=true";
import { default as _91id_93GkJWnI5HlEMeta } from "/codebuild/output/src457679548/src/pages/call_recordings/[id].vue?macro=true";
import { default as indexMJeNmUG2xIMeta } from "/codebuild/output/src457679548/src/pages/campaigns/[id]/index.vue?macro=true";
import { default as indexAPuma7pffhMeta } from "/codebuild/output/src457679548/src/pages/campaigns/index.vue?macro=true";
import { default as indexHEEL2pXGfmMeta } from "/codebuild/output/src457679548/src/pages/dashboard/index.vue?macro=true";
import { default as indexLNhNNY0PovMeta } from "/codebuild/output/src457679548/src/pages/help/index.vue?macro=true";
import { default as indexVxDRWidsKXMeta } from "/codebuild/output/src457679548/src/pages/index.vue?macro=true";
import { default as indexZkYIXr0TdAMeta } from "/codebuild/output/src457679548/src/pages/inquiry/index.vue?macro=true";
import { default as editorA4hVMAUXxrMeta } from "/codebuild/output/src457679548/src/pages/ivr_trees/[id]/editor.vue?macro=true";
import { default as indexxglWtNbb4tMeta } from "/codebuild/output/src457679548/src/pages/ivr_trees/index.vue?macro=true";
import { default as verify_emailVHzilHgNnTMeta } from "/codebuild/output/src457679548/src/pages/profiles/[user_id]/verify_email.vue?macro=true";
import { default as welcome_dashboardjeJKebHj5pMeta } from "/codebuild/output/src457679548/src/pages/profiles/[user_id]/welcome_dashboard.vue?macro=true";
import { default as indexS7mFU4KsOoMeta } from "/codebuild/output/src457679548/src/pages/profiles/index.vue?macro=true";
import { default as indexatBCxJAMYQMeta } from "/codebuild/output/src457679548/src/pages/sub_users/users/index.vue?macro=true";
import { default as index47CEph0Ix3Meta } from "/codebuild/output/src457679548/src/pages/terms_and_conditions/index.vue?macro=true";
import { default as indexhIozhTMNQ8Meta } from "/codebuild/output/src457679548/src/pages/users/password/edit/index.vue?macro=true";
import { default as indexig8od7tD3AMeta } from "/codebuild/output/src457679548/src/pages/users/password/new/index.vue?macro=true";
import { default as index1eKTGRbh6dMeta } from "/codebuild/output/src457679548/src/pages/users/sign_in/index.vue?macro=true";
export default [
  {
    name: admin8LZmwE0pW5Meta?.name ?? "admin",
    path: admin8LZmwE0pW5Meta?.path ?? "/admin",
    children: [
  {
    path: _91account_plan_id_93jRT8l39f2ZMeta?.path ?? "account_plans/:account_plan_id()",
    children: [
  {
    name: editnje6XZuNUJMeta?.name ?? "admin-account_plans-account_plan_id-edit",
    path: editnje6XZuNUJMeta?.path ?? "edit",
    meta: editnje6XZuNUJMeta || {},
    alias: editnje6XZuNUJMeta?.alias || [],
    redirect: editnje6XZuNUJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/account_plans/[account_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexRDkGzqBJAdMeta?.name ?? "admin-account_plans-account_plan_id",
    path: indexRDkGzqBJAdMeta?.path ?? "",
    meta: indexRDkGzqBJAdMeta || {},
    alias: indexRDkGzqBJAdMeta?.alias || [],
    redirect: indexRDkGzqBJAdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/account_plans/[account_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_plan_id_93jRT8l39f2ZMeta?.name ?? undefined,
    meta: _91account_plan_id_93jRT8l39f2ZMeta || {},
    alias: _91account_plan_id_93jRT8l39f2ZMeta?.alias || [],
    redirect: _91account_plan_id_93jRT8l39f2ZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/account_plans/[account_plan_id].vue").then(m => m.default || m)
  },
  {
    path: account_requested_plansBicvpjzzbYMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93Vx8lgfkvtfMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editLGh7s6sI9yMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id-edit",
    path: editLGh7s6sI9yMeta?.path ?? "edit",
    meta: editLGh7s6sI9yMeta || {},
    alias: editLGh7s6sI9yMeta?.alias || [],
    redirect: editLGh7s6sI9yMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexuEVt5UlUv2Meta?.name ?? "admin-account_requested_plans-account_requested_plan_id",
    path: indexuEVt5UlUv2Meta?.path ?? "",
    meta: indexuEVt5UlUv2Meta || {},
    alias: indexuEVt5UlUv2Meta?.alias || [],
    redirect: indexuEVt5UlUv2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93Vx8lgfkvtfMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93Vx8lgfkvtfMeta || {},
    alias: _91account_requested_plan_id_93Vx8lgfkvtfMeta?.alias || [],
    redirect: _91account_requested_plan_id_93Vx8lgfkvtfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexJTQasTlZmwMeta?.name ?? "admin-account_requested_plans",
    path: indexJTQasTlZmwMeta?.path ?? "",
    meta: indexJTQasTlZmwMeta || {},
    alias: indexJTQasTlZmwMeta?.alias || [],
    redirect: indexJTQasTlZmwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newT8sRV5dwp4Meta?.name ?? "admin-account_requested_plans-new",
    path: newT8sRV5dwp4Meta?.path ?? "new",
    meta: newT8sRV5dwp4Meta || {},
    alias: newT8sRV5dwp4Meta?.alias || [],
    redirect: newT8sRV5dwp4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plansBicvpjzzbYMeta?.name ?? undefined,
    meta: account_requested_plansBicvpjzzbYMeta || {},
    alias: account_requested_plansBicvpjzzbYMeta?.alias || [],
    redirect: account_requested_plansBicvpjzzbYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: accountsCl5FYHbJpuMeta?.path ?? "accounts",
    children: [
  {
    path: _91account_id_93WWmIhaeNJZMeta?.path ?? ":account_id()",
    children: [
  {
    path: account_requested_plansINPTWwhL5kMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93Sp3AGKXCG9Meta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editKVJy8u8SNAMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id-edit",
    path: editKVJy8u8SNAMeta?.path ?? "edit",
    meta: editKVJy8u8SNAMeta || {},
    alias: editKVJy8u8SNAMeta?.alias || [],
    redirect: editKVJy8u8SNAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indextFG82U0q7NMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id",
    path: indextFG82U0q7NMeta?.path ?? "",
    meta: indextFG82U0q7NMeta || {},
    alias: indextFG82U0q7NMeta?.alias || [],
    redirect: indextFG82U0q7NMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93Sp3AGKXCG9Meta?.name ?? undefined,
    meta: _91account_requested_plan_id_93Sp3AGKXCG9Meta || {},
    alias: _91account_requested_plan_id_93Sp3AGKXCG9Meta?.alias || [],
    redirect: _91account_requested_plan_id_93Sp3AGKXCG9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: index5TLyTD2wntMeta?.name ?? "admin-accounts-account_id-account_requested_plans",
    path: index5TLyTD2wntMeta?.path ?? "",
    meta: index5TLyTD2wntMeta || {},
    alias: index5TLyTD2wntMeta?.alias || [],
    redirect: index5TLyTD2wntMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newV58oDYmfgaMeta?.name ?? "admin-accounts-account_id-account_requested_plans-new",
    path: newV58oDYmfgaMeta?.path ?? "new",
    meta: newV58oDYmfgaMeta || {},
    alias: newV58oDYmfgaMeta?.alias || [],
    redirect: newV58oDYmfgaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plansINPTWwhL5kMeta?.name ?? undefined,
    meta: account_requested_plansINPTWwhL5kMeta || {},
    alias: account_requested_plansINPTWwhL5kMeta?.alias || [],
    redirect: account_requested_plansINPTWwhL5kMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: caller_idsIJxAwfWHKOMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_939CtrsPHM09Meta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editSMiD7VEnWlMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id-edit",
    path: editSMiD7VEnWlMeta?.path ?? "edit",
    meta: editSMiD7VEnWlMeta || {},
    alias: editSMiD7VEnWlMeta?.alias || [],
    redirect: editSMiD7VEnWlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexwRvbeHPjOiMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id",
    path: indexwRvbeHPjOiMeta?.path ?? "",
    meta: indexwRvbeHPjOiMeta || {},
    alias: indexwRvbeHPjOiMeta?.alias || [],
    redirect: indexwRvbeHPjOiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_939CtrsPHM09Meta?.name ?? undefined,
    meta: _91caller_id_id_939CtrsPHM09Meta || {},
    alias: _91caller_id_id_939CtrsPHM09Meta?.alias || [],
    redirect: _91caller_id_id_939CtrsPHM09Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexVyy3yM2rgmMeta?.name ?? "admin-accounts-account_id-caller_ids",
    path: indexVyy3yM2rgmMeta?.path ?? "",
    meta: indexVyy3yM2rgmMeta || {},
    alias: indexVyy3yM2rgmMeta?.alias || [],
    redirect: indexVyy3yM2rgmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newlyqQpHxqfDMeta?.name ?? "admin-accounts-account_id-caller_ids-new",
    path: newlyqQpHxqfDMeta?.path ?? "new",
    meta: newlyqQpHxqfDMeta || {},
    alias: newlyqQpHxqfDMeta?.alias || [],
    redirect: newlyqQpHxqfDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsIJxAwfWHKOMeta?.name ?? undefined,
    meta: caller_idsIJxAwfWHKOMeta || {},
    alias: caller_idsIJxAwfWHKOMeta?.alias || [],
    redirect: caller_idsIJxAwfWHKOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/caller_ids.vue").then(m => m.default || m)
  },
  {
    name: edity097BHc9fyMeta?.name ?? "admin-accounts-account_id-edit",
    path: edity097BHc9fyMeta?.path ?? "edit",
    meta: edity097BHc9fyMeta || {},
    alias: edity097BHc9fyMeta?.alias || [],
    redirect: edity097BHc9fyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexoUqfICqz5iMeta?.name ?? "admin-accounts-account_id",
    path: indexoUqfICqz5iMeta?.path ?? "",
    meta: indexoUqfICqz5iMeta || {},
    alias: indexoUqfICqz5iMeta?.alias || [],
    redirect: indexoUqfICqz5iMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/index.vue").then(m => m.default || m)
  },
  {
    path: sms_maskings8GT1MAuLxLMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93EmI6TVi5DTMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editJgobFdcL69Meta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id-edit",
    path: editJgobFdcL69Meta?.path ?? "edit",
    meta: editJgobFdcL69Meta || {},
    alias: editJgobFdcL69Meta?.alias || [],
    redirect: editJgobFdcL69Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexgoYT0OmJoHMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id",
    path: indexgoYT0OmJoHMeta?.path ?? "",
    meta: indexgoYT0OmJoHMeta || {},
    alias: indexgoYT0OmJoHMeta?.alias || [],
    redirect: indexgoYT0OmJoHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93EmI6TVi5DTMeta?.name ?? undefined,
    meta: _91sms_masking_id_93EmI6TVi5DTMeta || {},
    alias: _91sms_masking_id_93EmI6TVi5DTMeta?.alias || [],
    redirect: _91sms_masking_id_93EmI6TVi5DTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexYWkP7LqrdzMeta?.name ?? "admin-accounts-account_id-sms_maskings",
    path: indexYWkP7LqrdzMeta?.path ?? "",
    meta: indexYWkP7LqrdzMeta || {},
    alias: indexYWkP7LqrdzMeta?.alias || [],
    redirect: indexYWkP7LqrdzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newiGFhBWY9agMeta?.name ?? "admin-accounts-account_id-sms_maskings-new",
    path: newiGFhBWY9agMeta?.path ?? "new",
    meta: newiGFhBWY9agMeta || {},
    alias: newiGFhBWY9agMeta?.alias || [],
    redirect: newiGFhBWY9agMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskings8GT1MAuLxLMeta?.name ?? undefined,
    meta: sms_maskings8GT1MAuLxLMeta || {},
    alias: sms_maskings8GT1MAuLxLMeta?.alias || [],
    redirect: sms_maskings8GT1MAuLxLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id]/sms_maskings.vue").then(m => m.default || m)
  }
],
    name: _91account_id_93WWmIhaeNJZMeta?.name ?? undefined,
    meta: _91account_id_93WWmIhaeNJZMeta || {},
    alias: _91account_id_93WWmIhaeNJZMeta?.alias || [],
    redirect: _91account_id_93WWmIhaeNJZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/[account_id].vue").then(m => m.default || m)
  },
  {
    name: index0vjpC6oSxLMeta?.name ?? "admin-accounts",
    path: index0vjpC6oSxLMeta?.path ?? "",
    meta: index0vjpC6oSxLMeta || {},
    alias: index0vjpC6oSxLMeta?.alias || [],
    redirect: index0vjpC6oSxLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: wizard_plan_selectionegwDWOfHRiMeta?.name ?? "admin-accounts-wizard_plan_selection",
    path: wizard_plan_selectionegwDWOfHRiMeta?.path ?? "wizard_plan_selection",
    meta: wizard_plan_selectionegwDWOfHRiMeta || {},
    alias: wizard_plan_selectionegwDWOfHRiMeta?.alias || [],
    redirect: wizard_plan_selectionegwDWOfHRiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/wizard_plan_selection.vue").then(m => m.default || m)
  },
  {
    name: wizardB8FcY8TfunMeta?.name ?? "admin-accounts-wizard",
    path: wizardB8FcY8TfunMeta?.path ?? "wizard",
    meta: wizardB8FcY8TfunMeta || {},
    alias: wizardB8FcY8TfunMeta?.alias || [],
    redirect: wizardB8FcY8TfunMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts/wizard.vue").then(m => m.default || m)
  }
],
    name: accountsCl5FYHbJpuMeta?.name ?? undefined,
    meta: accountsCl5FYHbJpuMeta || {},
    alias: accountsCl5FYHbJpuMeta?.alias || [],
    redirect: accountsCl5FYHbJpuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    path: admin_users7aUZ1PVnNyMeta?.path ?? "admin_users",
    children: [
  {
    path: _91admin_user_id_93jDvyE6XV6YMeta?.path ?? ":admin_user_id()",
    children: [
  {
    name: editYgmvCYFUUsMeta?.name ?? "admin-admin_users-admin_user_id-edit",
    path: editYgmvCYFUUsMeta?.path ?? "edit",
    meta: editYgmvCYFUUsMeta || {},
    alias: editYgmvCYFUUsMeta?.alias || [],
    redirect: editYgmvCYFUUsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/admin_users/[admin_user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexyDsW6OQP8cMeta?.name ?? "admin-admin_users-admin_user_id",
    path: indexyDsW6OQP8cMeta?.path ?? "",
    meta: indexyDsW6OQP8cMeta || {},
    alias: indexyDsW6OQP8cMeta?.alias || [],
    redirect: indexyDsW6OQP8cMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/admin_users/[admin_user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91admin_user_id_93jDvyE6XV6YMeta?.name ?? undefined,
    meta: _91admin_user_id_93jDvyE6XV6YMeta || {},
    alias: _91admin_user_id_93jDvyE6XV6YMeta?.alias || [],
    redirect: _91admin_user_id_93jDvyE6XV6YMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/admin_users/[admin_user_id].vue").then(m => m.default || m)
  },
  {
    name: indexknl9EfMm2VMeta?.name ?? "admin-admin_users",
    path: indexknl9EfMm2VMeta?.path ?? "",
    meta: indexknl9EfMm2VMeta || {},
    alias: indexknl9EfMm2VMeta?.alias || [],
    redirect: indexknl9EfMm2VMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/admin_users/index.vue").then(m => m.default || m)
  },
  {
    name: newIaKHdcZCAsMeta?.name ?? "admin-admin_users-new",
    path: newIaKHdcZCAsMeta?.path ?? "new",
    meta: newIaKHdcZCAsMeta || {},
    alias: newIaKHdcZCAsMeta?.alias || [],
    redirect: newIaKHdcZCAsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/admin_users/new.vue").then(m => m.default || m)
  }
],
    name: admin_users7aUZ1PVnNyMeta?.name ?? undefined,
    meta: admin_users7aUZ1PVnNyMeta || {},
    alias: admin_users7aUZ1PVnNyMeta?.alias || [],
    redirect: admin_users7aUZ1PVnNyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/admin_users.vue").then(m => m.default || m)
  },
  {
    path: audit_trailsJ8zT6smBSKMeta?.path ?? "audit_trails",
    children: [
  {
    name: indexwJkJXcIpuDMeta?.name ?? "admin-audit_trails",
    path: indexwJkJXcIpuDMeta?.path ?? "",
    meta: indexwJkJXcIpuDMeta || {},
    alias: indexwJkJXcIpuDMeta?.alias || [],
    redirect: indexwJkJXcIpuDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/audit_trails/index.vue").then(m => m.default || m)
  }
],
    name: audit_trailsJ8zT6smBSKMeta?.name ?? undefined,
    meta: audit_trailsJ8zT6smBSKMeta || {},
    alias: audit_trailsJ8zT6smBSKMeta?.alias || [],
    redirect: audit_trailsJ8zT6smBSKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/audit_trails.vue").then(m => m.default || m)
  },
  {
    path: billingsUbhEDeoPxtMeta?.path ?? "billings",
    children: [
  {
    path: _91billing_id_93Y0bYnFp7DHMeta?.path ?? ":billing_id()",
    children: [
  {
    name: editOOioiOIa3NMeta?.name ?? "admin-billings-billing_id-edit",
    path: editOOioiOIa3NMeta?.path ?? "edit",
    meta: editOOioiOIa3NMeta || {},
    alias: editOOioiOIa3NMeta?.alias || [],
    redirect: editOOioiOIa3NMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/billings/[billing_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index7yoWg0daiaMeta?.name ?? "admin-billings-billing_id",
    path: index7yoWg0daiaMeta?.path ?? "",
    meta: index7yoWg0daiaMeta || {},
    alias: index7yoWg0daiaMeta?.alias || [],
    redirect: index7yoWg0daiaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/billings/[billing_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91billing_id_93Y0bYnFp7DHMeta?.name ?? undefined,
    meta: _91billing_id_93Y0bYnFp7DHMeta || {},
    alias: _91billing_id_93Y0bYnFp7DHMeta?.alias || [],
    redirect: _91billing_id_93Y0bYnFp7DHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/billings/[billing_id].vue").then(m => m.default || m)
  },
  {
    name: index9An1HxNb6BMeta?.name ?? "admin-billings",
    path: index9An1HxNb6BMeta?.path ?? "",
    meta: index9An1HxNb6BMeta || {},
    alias: index9An1HxNb6BMeta?.alias || [],
    redirect: index9An1HxNb6BMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/billings/index.vue").then(m => m.default || m)
  },
  {
    name: newljNMg2m5eoMeta?.name ?? "admin-billings-new",
    path: newljNMg2m5eoMeta?.path ?? "new",
    meta: newljNMg2m5eoMeta || {},
    alias: newljNMg2m5eoMeta?.alias || [],
    redirect: newljNMg2m5eoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/billings/new.vue").then(m => m.default || m)
  }
],
    name: billingsUbhEDeoPxtMeta?.name ?? undefined,
    meta: billingsUbhEDeoPxtMeta || {},
    alias: billingsUbhEDeoPxtMeta?.alias || [],
    redirect: billingsUbhEDeoPxtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/billings.vue").then(m => m.default || m)
  },
  {
    path: caller_idsYEDpZveR90Meta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93gqBBYA4A1lMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: edituhT8foAa6xMeta?.name ?? "admin-caller_ids-caller_id_id-edit",
    path: edituhT8foAa6xMeta?.path ?? "edit",
    meta: edituhT8foAa6xMeta || {},
    alias: edituhT8foAa6xMeta?.alias || [],
    redirect: edituhT8foAa6xMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexjpNJUZGBo2Meta?.name ?? "admin-caller_ids-caller_id_id",
    path: indexjpNJUZGBo2Meta?.path ?? "",
    meta: indexjpNJUZGBo2Meta || {},
    alias: indexjpNJUZGBo2Meta?.alias || [],
    redirect: indexjpNJUZGBo2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93gqBBYA4A1lMeta?.name ?? undefined,
    meta: _91caller_id_id_93gqBBYA4A1lMeta || {},
    alias: _91caller_id_id_93gqBBYA4A1lMeta?.alias || [],
    redirect: _91caller_id_id_93gqBBYA4A1lMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: index6qztIkBO3BMeta?.name ?? "admin-caller_ids",
    path: index6qztIkBO3BMeta?.path ?? "",
    meta: index6qztIkBO3BMeta || {},
    alias: index6qztIkBO3BMeta?.alias || [],
    redirect: index6qztIkBO3BMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newYP4u35XAbEMeta?.name ?? "admin-caller_ids-new",
    path: newYP4u35XAbEMeta?.path ?? "new",
    meta: newYP4u35XAbEMeta || {},
    alias: newYP4u35XAbEMeta?.alias || [],
    redirect: newYP4u35XAbEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsYEDpZveR90Meta?.name ?? undefined,
    meta: caller_idsYEDpZveR90Meta || {},
    alias: caller_idsYEDpZveR90Meta?.alias || [],
    redirect: caller_idsYEDpZveR90Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/caller_ids.vue").then(m => m.default || m)
  },
  {
    path: _91campaign_setting_id_93wWslQPd5WXMeta?.path ?? "campaign_settings/:campaign_setting_id()",
    children: [
  {
    name: edit2LzzE4awtwMeta?.name ?? "admin-campaign_settings-campaign_setting_id-edit",
    path: edit2LzzE4awtwMeta?.path ?? "edit",
    meta: edit2LzzE4awtwMeta || {},
    alias: edit2LzzE4awtwMeta?.alias || [],
    redirect: edit2LzzE4awtwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexGrwmTvYYYlMeta?.name ?? "admin-campaign_settings-campaign_setting_id",
    path: indexGrwmTvYYYlMeta?.path ?? "",
    meta: indexGrwmTvYYYlMeta || {},
    alias: indexGrwmTvYYYlMeta?.alias || [],
    redirect: indexGrwmTvYYYlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91campaign_setting_id_93wWslQPd5WXMeta?.name ?? undefined,
    meta: _91campaign_setting_id_93wWslQPd5WXMeta || {},
    alias: _91campaign_setting_id_93wWslQPd5WXMeta?.alias || [],
    redirect: _91campaign_setting_id_93wWslQPd5WXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaign_settings/[campaign_setting_id].vue").then(m => m.default || m)
  },
  {
    path: campaignsTZpDP9pEJvMeta?.path ?? "campaigns",
    children: [
  {
    path: _91campaign_id_93GveOb2rJjEMeta?.path ?? ":campaign_id()",
    children: [
  {
    name: editOCsnwqgA4OMeta?.name ?? "admin-campaigns-campaign_id-edit",
    path: editOCsnwqgA4OMeta?.path ?? "edit",
    meta: editOCsnwqgA4OMeta || {},
    alias: editOCsnwqgA4OMeta?.alias || [],
    redirect: editOCsnwqgA4OMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexzGLPbK56XCMeta?.name ?? "admin-campaigns-campaign_id",
    path: indexzGLPbK56XCMeta?.path ?? "",
    meta: indexzGLPbK56XCMeta || {},
    alias: indexzGLPbK56XCMeta?.alias || [],
    redirect: indexzGLPbK56XCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/index.vue").then(m => m.default || m)
  },
  {
    path: outbound_call_transactionsqqvpQvawJwMeta?.path ?? "outbound_call_transactions",
    children: [
  {
    path: _91outbound_call_transaction_id_938gGxxsEeR5Meta?.path ?? ":outbound_call_transaction_id()",
    children: [
  {
    name: editvHhQYYrJ8cMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id-edit",
    path: editvHhQYYrJ8cMeta?.path ?? "edit",
    meta: editvHhQYYrJ8cMeta || {},
    alias: editvHhQYYrJ8cMeta?.alias || [],
    redirect: editvHhQYYrJ8cMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexTc2b3Lu3GJMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id",
    path: indexTc2b3Lu3GJMeta?.path ?? "",
    meta: indexTc2b3Lu3GJMeta || {},
    alias: indexTc2b3Lu3GJMeta?.alias || [],
    redirect: indexTc2b3Lu3GJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91outbound_call_transaction_id_938gGxxsEeR5Meta?.name ?? undefined,
    meta: _91outbound_call_transaction_id_938gGxxsEeR5Meta || {},
    alias: _91outbound_call_transaction_id_938gGxxsEeR5Meta?.alias || [],
    redirect: _91outbound_call_transaction_id_938gGxxsEeR5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue").then(m => m.default || m)
  },
  {
    name: index4k9uujfPzQMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions",
    path: index4k9uujfPzQMeta?.path ?? "",
    meta: index4k9uujfPzQMeta || {},
    alias: index4k9uujfPzQMeta?.alias || [],
    redirect: index4k9uujfPzQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue").then(m => m.default || m)
  },
  {
    name: new4hwP6ze6i1Meta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-new",
    path: new4hwP6ze6i1Meta?.path ?? "new",
    meta: new4hwP6ze6i1Meta || {},
    alias: new4hwP6ze6i1Meta?.alias || [],
    redirect: new4hwP6ze6i1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue").then(m => m.default || m)
  }
],
    name: outbound_call_transactionsqqvpQvawJwMeta?.name ?? undefined,
    meta: outbound_call_transactionsqqvpQvawJwMeta || {},
    alias: outbound_call_transactionsqqvpQvawJwMeta?.alias || [],
    redirect: outbound_call_transactionsqqvpQvawJwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue").then(m => m.default || m)
  }
],
    name: _91campaign_id_93GveOb2rJjEMeta?.name ?? undefined,
    meta: _91campaign_id_93GveOb2rJjEMeta || {},
    alias: _91campaign_id_93GveOb2rJjEMeta?.alias || [],
    redirect: _91campaign_id_93GveOb2rJjEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns/[campaign_id].vue").then(m => m.default || m)
  },
  {
    name: indexafbGFwE2gAMeta?.name ?? "admin-campaigns",
    path: indexafbGFwE2gAMeta?.path ?? "",
    meta: indexafbGFwE2gAMeta || {},
    alias: indexafbGFwE2gAMeta?.alias || [],
    redirect: indexafbGFwE2gAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: newKk5fbZyaonMeta?.name ?? "admin-campaigns-new",
    path: newKk5fbZyaonMeta?.path ?? "new",
    meta: newKk5fbZyaonMeta || {},
    alias: newKk5fbZyaonMeta?.alias || [],
    redirect: newKk5fbZyaonMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns/new.vue").then(m => m.default || m)
  }
],
    name: campaignsTZpDP9pEJvMeta?.name ?? undefined,
    meta: campaignsTZpDP9pEJvMeta || {},
    alias: campaignsTZpDP9pEJvMeta?.alias || [],
    redirect: campaignsTZpDP9pEJvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/campaigns.vue").then(m => m.default || m)
  },
  {
    path: custom_emailsJhd0wiKPWmMeta?.path ?? "custom_emails",
    children: [
  {
    path: _91custom_email_id_93wnIdfHKbFWMeta?.path ?? ":custom_email_id()",
    children: [
  {
    name: indexVyEy8iGibfMeta?.name ?? "admin-custom_emails-custom_email_id",
    path: indexVyEy8iGibfMeta?.path ?? "",
    meta: indexVyEy8iGibfMeta || {},
    alias: indexVyEy8iGibfMeta?.alias || [],
    redirect: indexVyEy8iGibfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/custom_emails/[custom_email_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91custom_email_id_93wnIdfHKbFWMeta?.name ?? undefined,
    meta: _91custom_email_id_93wnIdfHKbFWMeta || {},
    alias: _91custom_email_id_93wnIdfHKbFWMeta?.alias || [],
    redirect: _91custom_email_id_93wnIdfHKbFWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/custom_emails/[custom_email_id].vue").then(m => m.default || m)
  },
  {
    name: indexvlsJMU8744Meta?.name ?? "admin-custom_emails",
    path: indexvlsJMU8744Meta?.path ?? "",
    meta: indexvlsJMU8744Meta || {},
    alias: indexvlsJMU8744Meta?.alias || [],
    redirect: indexvlsJMU8744Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/custom_emails/index.vue").then(m => m.default || m)
  },
  {
    name: newZoClRg2yymMeta?.name ?? "admin-custom_emails-new",
    path: newZoClRg2yymMeta?.path ?? "new",
    meta: newZoClRg2yymMeta || {},
    alias: newZoClRg2yymMeta?.alias || [],
    redirect: newZoClRg2yymMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/custom_emails/new.vue").then(m => m.default || m)
  }
],
    name: custom_emailsJhd0wiKPWmMeta?.name ?? undefined,
    meta: custom_emailsJhd0wiKPWmMeta || {},
    alias: custom_emailsJhd0wiKPWmMeta?.alias || [],
    redirect: custom_emailsJhd0wiKPWmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/custom_emails.vue").then(m => m.default || m)
  },
  {
    path: dashboardBJjjGVrMVNMeta?.path ?? "dashboard",
    children: [
  {
    name: indexdsNwl8OaWdMeta?.name ?? "admin-dashboard",
    path: indexdsNwl8OaWdMeta?.path ?? "",
    meta: indexdsNwl8OaWdMeta || {},
    alias: indexdsNwl8OaWdMeta?.alias || [],
    redirect: indexdsNwl8OaWdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/dashboard/index.vue").then(m => m.default || m)
  }
],
    name: dashboardBJjjGVrMVNMeta?.name ?? undefined,
    meta: dashboardBJjjGVrMVNMeta || {},
    alias: dashboardBJjjGVrMVNMeta?.alias || [],
    redirect: dashboardBJjjGVrMVNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    path: inquiriestHjQpjBEvwMeta?.path ?? "inquiries",
    children: [
  {
    path: _91inquiry_id_93bo5gCCUPXgMeta?.path ?? ":inquiry_id()",
    children: [
  {
    name: indexROeIQuqRN1Meta?.name ?? "admin-inquiries-inquiry_id",
    path: indexROeIQuqRN1Meta?.path ?? "",
    meta: indexROeIQuqRN1Meta || {},
    alias: indexROeIQuqRN1Meta?.alias || [],
    redirect: indexROeIQuqRN1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/inquiries/[inquiry_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91inquiry_id_93bo5gCCUPXgMeta?.name ?? undefined,
    meta: _91inquiry_id_93bo5gCCUPXgMeta || {},
    alias: _91inquiry_id_93bo5gCCUPXgMeta?.alias || [],
    redirect: _91inquiry_id_93bo5gCCUPXgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/inquiries/[inquiry_id].vue").then(m => m.default || m)
  },
  {
    name: index1WavoXruukMeta?.name ?? "admin-inquiries",
    path: index1WavoXruukMeta?.path ?? "",
    meta: index1WavoXruukMeta || {},
    alias: index1WavoXruukMeta?.alias || [],
    redirect: index1WavoXruukMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/inquiries/index.vue").then(m => m.default || m)
  },
  {
    name: newJixyayeRWEMeta?.name ?? "admin-inquiries-new",
    path: newJixyayeRWEMeta?.path ?? "new",
    meta: newJixyayeRWEMeta || {},
    alias: newJixyayeRWEMeta?.alias || [],
    redirect: newJixyayeRWEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/inquiries/new.vue").then(m => m.default || m)
  }
],
    name: inquiriestHjQpjBEvwMeta?.name ?? undefined,
    meta: inquiriestHjQpjBEvwMeta || {},
    alias: inquiriestHjQpjBEvwMeta?.alias || [],
    redirect: inquiriestHjQpjBEvwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/inquiries.vue").then(m => m.default || m)
  },
  {
    path: login3MDQy1mkOoMeta?.path ?? "login",
    children: [
  {
    name: indexa9UR9hbg2YMeta?.name ?? "admin-login",
    path: indexa9UR9hbg2YMeta?.path ?? "",
    meta: indexa9UR9hbg2YMeta || {},
    alias: indexa9UR9hbg2YMeta?.alias || [],
    redirect: indexa9UR9hbg2YMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/login/index.vue").then(m => m.default || m)
  }
],
    name: login3MDQy1mkOoMeta?.name ?? undefined,
    meta: login3MDQy1mkOoMeta || {},
    alias: login3MDQy1mkOoMeta?.alias || [],
    redirect: login3MDQy1mkOoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: newIiF1AIl57tMeta?.name ?? "admin-password-new",
    path: newIiF1AIl57tMeta?.path ?? "password/new",
    meta: newIiF1AIl57tMeta || {},
    alias: newIiF1AIl57tMeta?.alias || [],
    redirect: newIiF1AIl57tMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/password/new.vue").then(m => m.default || m)
  },
  {
    path: reportsFnapGCm3lhMeta?.path ?? "reports",
    children: [
  {
    name: indexUMk1JJA8OMMeta?.name ?? "admin-reports",
    path: indexUMk1JJA8OMMeta?.path ?? "",
    meta: indexUMk1JJA8OMMeta || {},
    alias: indexUMk1JJA8OMMeta?.alias || [],
    redirect: indexUMk1JJA8OMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/reports/index.vue").then(m => m.default || m)
  }
],
    name: reportsFnapGCm3lhMeta?.name ?? undefined,
    meta: reportsFnapGCm3lhMeta || {},
    alias: reportsFnapGCm3lhMeta?.alias || [],
    redirect: reportsFnapGCm3lhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    path: sms_maskings0QGAs4u8g7Meta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93NUM3pN6WW8Meta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editzNuPUsHL7LMeta?.name ?? "admin-sms_maskings-sms_masking_id-edit",
    path: editzNuPUsHL7LMeta?.path ?? "edit",
    meta: editzNuPUsHL7LMeta || {},
    alias: editzNuPUsHL7LMeta?.alias || [],
    redirect: editzNuPUsHL7LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexrbAOYsSignMeta?.name ?? "admin-sms_maskings-sms_masking_id",
    path: indexrbAOYsSignMeta?.path ?? "",
    meta: indexrbAOYsSignMeta || {},
    alias: indexrbAOYsSignMeta?.alias || [],
    redirect: indexrbAOYsSignMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93NUM3pN6WW8Meta?.name ?? undefined,
    meta: _91sms_masking_id_93NUM3pN6WW8Meta || {},
    alias: _91sms_masking_id_93NUM3pN6WW8Meta?.alias || [],
    redirect: _91sms_masking_id_93NUM3pN6WW8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexWA4uEKQhzpMeta?.name ?? "admin-sms_maskings",
    path: indexWA4uEKQhzpMeta?.path ?? "",
    meta: indexWA4uEKQhzpMeta || {},
    alias: indexWA4uEKQhzpMeta?.alias || [],
    redirect: indexWA4uEKQhzpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newDaag2VN2pqMeta?.name ?? "admin-sms_maskings-new",
    path: newDaag2VN2pqMeta?.path ?? "new",
    meta: newDaag2VN2pqMeta || {},
    alias: newDaag2VN2pqMeta?.alias || [],
    redirect: newDaag2VN2pqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskings0QGAs4u8g7Meta?.name ?? undefined,
    meta: sms_maskings0QGAs4u8g7Meta || {},
    alias: sms_maskings0QGAs4u8g7Meta?.alias || [],
    redirect: sms_maskings0QGAs4u8g7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/sms_maskings.vue").then(m => m.default || m)
  },
  {
    path: usersfi9Mdvx86EMeta?.path ?? "users",
    children: [
  {
    path: _91user_id_933ITIUmxuIJMeta?.path ?? ":user_id()",
    children: [
  {
    name: editOUt1oftNVYMeta?.name ?? "admin-users-user_id-edit",
    path: editOUt1oftNVYMeta?.path ?? "edit",
    meta: editOUt1oftNVYMeta || {},
    alias: editOUt1oftNVYMeta?.alias || [],
    redirect: editOUt1oftNVYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexRPb1JirnEdMeta?.name ?? "admin-users-user_id",
    path: indexRPb1JirnEdMeta?.path ?? "",
    meta: indexRPb1JirnEdMeta || {},
    alias: indexRPb1JirnEdMeta?.alias || [],
    redirect: indexRPb1JirnEdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/users/[user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91user_id_933ITIUmxuIJMeta?.name ?? undefined,
    meta: _91user_id_933ITIUmxuIJMeta || {},
    alias: _91user_id_933ITIUmxuIJMeta?.alias || [],
    redirect: _91user_id_933ITIUmxuIJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/users/[user_id].vue").then(m => m.default || m)
  },
  {
    name: indexpbPVAKcKuKMeta?.name ?? "admin-users",
    path: indexpbPVAKcKuKMeta?.path ?? "",
    meta: indexpbPVAKcKuKMeta || {},
    alias: indexpbPVAKcKuKMeta?.alias || [],
    redirect: indexpbPVAKcKuKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: newSIrvJNlmSMMeta?.name ?? "admin-users-new",
    path: newSIrvJNlmSMMeta?.path ?? "new",
    meta: newSIrvJNlmSMMeta || {},
    alias: newSIrvJNlmSMMeta?.alias || [],
    redirect: newSIrvJNlmSMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/users/new.vue").then(m => m.default || m)
  }
],
    name: usersfi9Mdvx86EMeta?.name ?? undefined,
    meta: usersfi9Mdvx86EMeta || {},
    alias: usersfi9Mdvx86EMeta?.alias || [],
    redirect: usersfi9Mdvx86EMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    path: _91wallet_id_93oHNjl8XQTRMeta?.path ?? "wallets/:wallet_id()",
    children: [
  {
    name: editTl4z41I6t3Meta?.name ?? "admin-wallets-wallet_id-edit",
    path: editTl4z41I6t3Meta?.path ?? "edit",
    meta: editTl4z41I6t3Meta || {},
    alias: editTl4z41I6t3Meta?.alias || [],
    redirect: editTl4z41I6t3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/wallets/[wallet_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexH5AJYWtJCgMeta?.name ?? "admin-wallets-wallet_id",
    path: indexH5AJYWtJCgMeta?.path ?? "",
    meta: indexH5AJYWtJCgMeta || {},
    alias: indexH5AJYWtJCgMeta?.alias || [],
    redirect: indexH5AJYWtJCgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/wallets/[wallet_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91wallet_id_93oHNjl8XQTRMeta?.name ?? undefined,
    meta: _91wallet_id_93oHNjl8XQTRMeta || {},
    alias: _91wallet_id_93oHNjl8XQTRMeta?.alias || [],
    redirect: _91wallet_id_93oHNjl8XQTRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin/wallets/[wallet_id].vue").then(m => m.default || m)
  }
],
    meta: admin8LZmwE0pW5Meta || {},
    alias: admin8LZmwE0pW5Meta?.alias || [],
    redirect: admin8LZmwE0pW5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indextbKQ8ygAKjMeta?.name ?? "audit_logs",
    path: indextbKQ8ygAKjMeta?.path ?? "/audit_logs",
    meta: indextbKQ8ygAKjMeta || {},
    alias: indextbKQ8ygAKjMeta?.alias || [],
    redirect: indextbKQ8ygAKjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/audit_logs/index.vue").then(m => m.default || m)
  },
  {
    name: index5me68PGBLuMeta?.name ?? "billings",
    path: index5me68PGBLuMeta?.path ?? "/billings",
    meta: index5me68PGBLuMeta || {},
    alias: index5me68PGBLuMeta?.alias || [],
    redirect: index5me68PGBLuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/billings/index.vue").then(m => m.default || m)
  },
  {
    name: indexDC3pbQoeAzMeta?.name ?? "call_out-reports",
    path: indexDC3pbQoeAzMeta?.path ?? "/call_out/reports",
    meta: indexDC3pbQoeAzMeta || {},
    alias: indexDC3pbQoeAzMeta?.alias || [],
    redirect: indexDC3pbQoeAzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/call_out/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GkJWnI5HlEMeta?.name ?? "call_recordings-id",
    path: _91id_93GkJWnI5HlEMeta?.path ?? "/call_recordings/:id()",
    meta: _91id_93GkJWnI5HlEMeta || {},
    alias: _91id_93GkJWnI5HlEMeta?.alias || [],
    redirect: _91id_93GkJWnI5HlEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/call_recordings/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMJeNmUG2xIMeta?.name ?? "campaigns-id",
    path: indexMJeNmUG2xIMeta?.path ?? "/campaigns/:id()",
    meta: indexMJeNmUG2xIMeta || {},
    alias: indexMJeNmUG2xIMeta?.alias || [],
    redirect: indexMJeNmUG2xIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/campaigns/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAPuma7pffhMeta?.name ?? "campaigns",
    path: indexAPuma7pffhMeta?.path ?? "/campaigns",
    meta: indexAPuma7pffhMeta || {},
    alias: indexAPuma7pffhMeta?.alias || [],
    redirect: indexAPuma7pffhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexHEEL2pXGfmMeta?.name ?? "dashboard",
    path: indexHEEL2pXGfmMeta?.path ?? "/dashboard",
    meta: indexHEEL2pXGfmMeta || {},
    alias: indexHEEL2pXGfmMeta?.alias || [],
    redirect: indexHEEL2pXGfmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexLNhNNY0PovMeta?.name ?? "help",
    path: indexLNhNNY0PovMeta?.path ?? "/help",
    meta: indexLNhNNY0PovMeta || {},
    alias: indexLNhNNY0PovMeta?.alias || [],
    redirect: indexLNhNNY0PovMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxDRWidsKXMeta?.name ?? "index",
    path: indexVxDRWidsKXMeta?.path ?? "/",
    meta: indexVxDRWidsKXMeta || {},
    alias: indexVxDRWidsKXMeta?.alias || [],
    redirect: indexVxDRWidsKXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexZkYIXr0TdAMeta?.name ?? "inquiry",
    path: indexZkYIXr0TdAMeta?.path ?? "/inquiry",
    meta: indexZkYIXr0TdAMeta || {},
    alias: indexZkYIXr0TdAMeta?.alias || [],
    redirect: indexZkYIXr0TdAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: editorA4hVMAUXxrMeta?.name ?? "ivr_trees-id-editor",
    path: editorA4hVMAUXxrMeta?.path ?? "/ivr_trees/:id()/editor",
    meta: editorA4hVMAUXxrMeta || {},
    alias: editorA4hVMAUXxrMeta?.alias || [],
    redirect: editorA4hVMAUXxrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/ivr_trees/[id]/editor.vue").then(m => m.default || m)
  },
  {
    name: indexxglWtNbb4tMeta?.name ?? "ivr_trees",
    path: indexxglWtNbb4tMeta?.path ?? "/ivr_trees",
    meta: indexxglWtNbb4tMeta || {},
    alias: indexxglWtNbb4tMeta?.alias || [],
    redirect: indexxglWtNbb4tMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/ivr_trees/index.vue").then(m => m.default || m)
  },
  {
    name: verify_emailVHzilHgNnTMeta?.name ?? "profiles-user_id-verify_email",
    path: verify_emailVHzilHgNnTMeta?.path ?? "/profiles/:user_id()/verify_email",
    meta: verify_emailVHzilHgNnTMeta || {},
    alias: verify_emailVHzilHgNnTMeta?.alias || [],
    redirect: verify_emailVHzilHgNnTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/profiles/[user_id]/verify_email.vue").then(m => m.default || m)
  },
  {
    name: welcome_dashboardjeJKebHj5pMeta?.name ?? "profiles-user_id-welcome_dashboard",
    path: welcome_dashboardjeJKebHj5pMeta?.path ?? "/profiles/:user_id()/welcome_dashboard",
    meta: welcome_dashboardjeJKebHj5pMeta || {},
    alias: welcome_dashboardjeJKebHj5pMeta?.alias || [],
    redirect: welcome_dashboardjeJKebHj5pMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/profiles/[user_id]/welcome_dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexS7mFU4KsOoMeta?.name ?? "profiles",
    path: indexS7mFU4KsOoMeta?.path ?? "/profiles",
    meta: indexS7mFU4KsOoMeta || {},
    alias: indexS7mFU4KsOoMeta?.alias || [],
    redirect: indexS7mFU4KsOoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: indexatBCxJAMYQMeta?.name ?? "sub_users-users",
    path: indexatBCxJAMYQMeta?.path ?? "/sub_users/users",
    meta: indexatBCxJAMYQMeta || {},
    alias: indexatBCxJAMYQMeta?.alias || [],
    redirect: indexatBCxJAMYQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/sub_users/users/index.vue").then(m => m.default || m)
  },
  {
    name: index47CEph0Ix3Meta?.name ?? "terms_and_conditions",
    path: index47CEph0Ix3Meta?.path ?? "/terms_and_conditions",
    meta: index47CEph0Ix3Meta || {},
    alias: index47CEph0Ix3Meta?.alias || [],
    redirect: index47CEph0Ix3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/terms_and_conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexhIozhTMNQ8Meta?.name ?? "users-password-edit",
    path: indexhIozhTMNQ8Meta?.path ?? "/users/password/edit",
    meta: indexhIozhTMNQ8Meta || {},
    alias: indexhIozhTMNQ8Meta?.alias || [],
    redirect: indexhIozhTMNQ8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/users/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexig8od7tD3AMeta?.name ?? "users-password-new",
    path: indexig8od7tD3AMeta?.path ?? "/users/password/new",
    meta: indexig8od7tD3AMeta || {},
    alias: indexig8od7tD3AMeta?.alias || [],
    redirect: indexig8od7tD3AMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/users/password/new/index.vue").then(m => m.default || m)
  },
  {
    name: index1eKTGRbh6dMeta?.name ?? "users-sign_in",
    path: index1eKTGRbh6dMeta?.path ?? "/users/sign_in",
    meta: index1eKTGRbh6dMeta || {},
    alias: index1eKTGRbh6dMeta?.alias || [],
    redirect: index1eKTGRbh6dMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src457679548/src/pages/users/sign_in/index.vue").then(m => m.default || m)
  }
]